import {USER_HIDE_POINTS, USER_INPUT_CHANGE, USER_REGISTER_PREV, USER_LOGIN_SUCCESS, USER_REGISTER, USER_REGISTER_ERROR, USER_REGISTER_NEXT, USER_REGISTER_SUCCESS, USER_SET_USERFRAGEN, USER_SET_USERFRAGEN_ERROR, USER_SET_USERFRAGEN_SUCCESS, USER_SHOW_POINTS} from './../actions/types';
import { setCookie } from './../globals/helpers'

const INITIAL_STATE = {
  userId: '',
  groupId: '',
  groupname: '',
  username: '',
  position: 0,
  vorname: '',
  nachname: '',
  handynummer: '',
  accesstoken: 'debug_accesstoken',
  code: '',
  registerStep: 0,
  isLoading: false,
  errorMessage: '',
  showPointsOverlay: false,
  hasAnsweredQuestions: false,
  pointsToShow: 0,
  userscore: 0,
  deadline: new Date('2020-10-06T12:00:00'),
};




export const UserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_REGISTER_NEXT:
      return {...state, registerStep: state.registerStep+1}
    case USER_REGISTER_PREV:
      return {...state, registerStep: state.registerStep-1}
    case USER_REGISTER: 
      return {...state, isLoading: true, errorMessage: ''};
    case USER_REGISTER_ERROR:
      return {...state, isLoading: false, errorMessage: action.payload};
    case USER_REGISTER_SUCCESS: 
       return {...state, isLoading: false, registerStep: 3, uid: action.payload.uid};
    case USER_SHOW_POINTS:
      return {...state, 
        showPointsOverlay: true,
        pointsToShow: action.payload,
        userscore: state.userscore + action.payload,
      }
    case USER_HIDE_POINTS:
      return {...state, 
        showPointsOverlay: false,
        pointsToShow: 0,
      }
    case USER_INPUT_CHANGE:
      return {...state, errorMessage: '', [action.payload.name]: action.payload.value, errorMessage: ''}
   
    case USER_LOGIN_SUCCESS:


      setCookie("accesstoken", action.payload.user.accesstoken, 14)

      return {
        ...state,
        errorMessage: '',
        username: action.payload.user.username,
        userId: action.payload.user.uid,
        //userId: action.payload.user.uid,
        hasAnsweredQuestions: (action.payload.user.fragen) ? true : false,
        userscore: action.payload.user.userscore,
        position: action.payload.user.position,
        deadline: new Date(action.payload.deadline),
        groupname: action.payload.groupname,
        accesstoken: action.payload.user.accesstoken,
        isSignedIn: true,
        isLoading: false,
      };
    case USER_SET_USERFRAGEN:
      return {...state, isLoading: true};
    case USER_SET_USERFRAGEN_ERROR:
      return {...state, isLoading: false, errorMessage: action.payload};
    case USER_SET_USERFRAGEN_SUCCESS:
      return {...state, isLoading: false, hasAnsweredQuestions: true};
    default:
      return state;
  }
};


