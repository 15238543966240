import React from 'react';
import './Topic.css';
import TopicBackground from './TopicBackground';
import TopicCounter from './TopicCounter';
import TopicTimer from './TopicTimer';



const BACKGROUND_COLOR_OPEN = '#000000';
const BACKGROUND_COLOR_LOCKED = '#00ffff';
const BACKGROUND_COLOR_VOTING = '#000000';
const BACKGROUND_COLOR_DECIDED_OK = '#33FFCC';
const BACKGROUND_COLOR_DECIDED_VETO = '#ffe26f';

const STROKE_COLOR_OPEN = '#ffffff';
const STOKE_COLOR_LOCKED = '#00ffff';
const STROKE_COLOR_VOTING = '#00FFFF';
const STROKE_COLOR_DECIDED_OK = '#33FFCC';
const STROKE_COLOR_DECIDED_VETO = '#ffe26f';

const TEXT_COLOR_OPEN = '#ffffff';
const TEXT_COLOR_LOCKED = '#000000';
const TEXT_COLOR_VOTING = '#00FFFF';
const TEXT_COLOR_DECIDED = '#000000';


export const topicState = {
    OPEN : 'open',
    LOCKED: 'locked',
    VOTING: 'voting',
    VOTED: 'voted',
}


function renderHeadline(title, textColor, backgroundColor, topPosition) {
    return (
        <div style={{
            color: textColor,
            backgroundColor: backgroundColor,
            padding: '6px',
            top: topPosition
       }} className="topicHeadline">{title}</div>
    )
}


export default function Topic ({id, title, state, deadline, position, onPress, commentsCount, statementsCount, scale = 1, okCount, vetoCount}) {

    

    switch (state) {
        case topicState.OPEN:
            return (
                <div onClick={()=>onPress(id, title,deadline,state)} className={`topicContainer topicContainer${position}`}>
                    {renderHeadline(title, TEXT_COLOR_OPEN, BACKGROUND_COLOR_OPEN, 80*scale+'px')}
                    <div className="topicCounterPosition"><TopicCounter statementsCount={statementsCount} commentsCount={commentsCount}></TopicCounter></div>
                    <TopicBackground className="topicBackground" scale={scale} position={position} backgroundColor={BACKGROUND_COLOR_OPEN} strokeColor={STROKE_COLOR_OPEN}></TopicBackground>
                    <div className={`topicIcon topicIcon${position}`}></div>
                    <div className="topicTimerPosition"><TopicTimer deadline={deadline}></TopicTimer></div>
                </div>
            )
        case topicState.VOTING:
            return (
                 <div onClick={()=>onPress(id, title,deadline,state)} className={`topicContainer topicContainer${position}`}>
                    {renderHeadline(title, TEXT_COLOR_VOTING, BACKGROUND_COLOR_VOTING, 40*scale+'px')}
                    <TopicBackground className="topicBackground" scale={scale} position={position} backgroundColor={BACKGROUND_COLOR_VOTING} strokeColor={STROKE_COLOR_VOTING}></TopicBackground>
                    <div className="topicLosPosition">DAS<br/>LOS<br/>WÄHLT</div>
                    <div className="topicTimerPosition"><TopicTimer textColor="#00ffff" deadline={deadline}></TopicTimer></div>
                 </div>
            )
        case topicState.VOTED:


            const bgColor = okCount > vetoCount ? BACKGROUND_COLOR_DECIDED_OK : BACKGROUND_COLOR_DECIDED_VETO
            const strokeColor = okCount > vetoCount ? STROKE_COLOR_DECIDED_OK : STROKE_COLOR_DECIDED_VETO


            return (
                 <div onClick={()=>onPress(id, title,deadline,state)} className={`topicContainer topicContainer${position}`}>
                    {renderHeadline(title, TEXT_COLOR_DECIDED, bgColor, 80*scale+'px')}
                    <TopicBackground className="topicTimerPosition" scale={scale} position={position} backgroundColor={bgColor} strokeColor={strokeColor}></TopicBackground>
                    <div className="topicVotesPosition">{okCount}<span style={{fontSize:'0.5em'}}>/</span>{vetoCount}</div>
                    <div className={`topicIcon topicIconBlack${position}`}></div>
                 </div>
            )
        case topicState.LOCKED:
        default:
            return (
                <div onClick={()=>onPress(id, title,deadline,state)} className={`topicContainer topicContainer${position}`}>
                    {renderHeadline(title, TEXT_COLOR_LOCKED, BACKGROUND_COLOR_LOCKED, 80*scale+'px')}
                    <TopicBackground className="topicBackground" scale={scale} position={position} backgroundColor={BACKGROUND_COLOR_LOCKED} strokeColor={STOKE_COLOR_LOCKED}></TopicBackground>
                    <div className="topicLocked"></div>
                    <div className="topicTimerPosition"><TopicTimer deadline={deadline}></TopicTimer></div>
                </div>
            )
    }

};
