import React, { Component } from 'react';
import './Statement.css';
import './../../globals/styles.css';
import VoteButton, { VoteButtonFor, VoteButtonTypes } from './VoteButton';


export default class Statement extends Component {

    constructor(props) {
        super();
        this.state = {
            loading: false,
        }
    }

    onVote = ({objectId, userId, count}) => {
        console.log(userId + ' VOTES ' + count + ' FOR ' + objectId)
        if (count > 0) {
            this.props.onLike(objectId)
        } else {
            this.props.onDislike(objectId)
        }
    }

    render() {

    
        const { sid, statement, username, userscore, likes, dislikes, comments, userVote } = this.props.data;

        const {onPress, data, selected, showLikes, showComments, disableLike } = this.props
        const showBackground = this.props.showBackground;

    
       console.log("SHOW: " +showLikes);
       
        let selectedClassName='';
        if (selected) selectedClassName='selected';

        // {showBackground && (<div className="statementBackground"></div>)}
        let bgClassName = '';
        if (showBackground) {
            bgClassName='statementBackground';
        }


        //  <div className="statementUserscore">{userscore} Punkte</div>

        return(
            <div className={`statementContainer ${bgClassName} ${selectedClassName}`}>
               
                <div onClick={()=>onPress(data)}>
                    <div className="statementUsername">{username}</div>
                  
                    <div className="statementCopy">{statement}</div>
                    {showComments && (
                      <div className="statementComments">{comments} Kommentare</div>
                    )}
                </div>
                {showLikes && (
                <div className="statementVotingContainer">
                    <VoteButton voteFor={VoteButtonFor.STATEMENT} float="left" isDisabled={disableLike} isHighlighted={userVote > 0} count={likes} type={VoteButtonTypes.LIKE} objectId={sid} userId="me" onVote={this.onVote}></VoteButton>
                    {showComments && (
                        <div onClick={()=>onPress(data)} className="statementComment">kommentieren</div>
                    )}
                    <VoteButton voteFor={VoteButtonFor.STATEMENT} float="right" isDisabled={disableLike} isHighlighted={userVote < 0} count={dislikes} type={VoteButtonTypes.DISLIKE} objectId={sid} userId="me" onVote={this.onVote}></VoteButton>
                </div>
                )}
            </div>
        )
    }

};
