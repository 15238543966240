import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCookie } from './../../globals/helpers';
import { push } from 'connected-react-router'
import { onChangeUserInput, onRegister, onRegisterNextStep, onRegisterPrevStep } from './../../actions/UserActions'
import { signIn, autoSignIn  } from './../../actions/AuthActions'
import AppButton, { AppButtonColor } from '../ui/AppButton';
import Appcheckbox from '../ui/AppCheckbox';
import AppTextInput from '../ui/AppTextInput';
import './Login.css';
import './../../globals/styles.css';
class Login extends Component {

    constructor(props) {
        super();
        this.state = {
            isLoading: false,
            allowMessages: false,
            agreedDisclaimer: false,
            step: 0,
        }
    }

    componentDidMount() {
       const accesstoken = getCookie('accesstoken');
       console.log('ACCESTOKEN: ' +accesstoken);
        this.props.autoSignIn({accesstoken});
    }

    onDatenSchutz = () => {
        this.props.push('/datenschutz')
    }
    
    onPrevStep = () => {
        if (this.props.user.registerStep > 0) {
            this.props.onRegisterPrevStep();
        }
    }

    onNextStep = () => {

        const {registerStep} = this.props.user;

        let msg='';

        if (registerStep === 1) {


            if (this.props.user.vorname.length<1) msg+='Bitte gib deinen Vornamen an.\n';
            if (this.props.user.nachname.length<1) msg+='Bitte gib deinen Nachname an.\n';
            if (this.props.user.groupId.length<1) msg+='Bitte gib deine Gruppekennung an.\n';

            if (msg !== '') {
                alert(msg);
                return;
            }
        }

        if (registerStep === 2) {
            if (this.props.user.username.length<1) msg+='Bitte gib deinen Username an.\n';
            if (this.props.user.handynummer.length<5) msg+='Bitte gib deine Handynummer an.\n';
        
            if (msg !== '') {
                alert(msg);
                return;
            }

            if (this.state.allowMessages && this.state.agreedDisclaimer) {
                this.props.onRegister({
                    vorname: this.props.user.vorname,
                    nachname: this.props.user.nachname,
                    username: this.props.user.username,
                    handynummer: this.props.user.handynummer,
                    groupId: this.props.user.groupId,
                    datenschutz: this.state.allowMessages,
                    disclaimer: this.state.agreedDisclaimer
                })
            } else {
                alert("Bitte stimmen den Datenschutz und Nachrichtenversand zu")
            }
        } else {
            this.props.onRegisterNextStep();
        }
    }

    onInputChange = (evt) => {
      
       this.props.onChangeUserInput({name: evt.target.name, value: evt.target.value})
    }
    onSignIn = () => {
        const {username, code, groupId} = this.props.user;

        if (code === '') {
            alert('Bitte gib deinen SMS-Code an.');
            return;
        }

        this.props.signIn({username:username, code: code, gid: groupId});
    }


    onCheck = (name, value) => {
        switch (name) {
            case "disclaimer":
                this.setState({
                    agreedDisclaimer: !value
                })
                break;
            case "messages":
                this.setState({
                    allowMessages: !value
                })
                break;
            default:
                console.log('Keinen passenden Wert gefunden')
                break;
        }
    }

    renderLoginForm() {

        const {username, groupId, code } = this.props.user;
        const { errorMessage} = this.props.auth;

        return (
             <form className="loginForm" name="LoginForm">
                <div className="loginFormTitle">Bitte melde<br/>dich an:</div>
                <div className="loginFormFields">
                    <AppTextInput onChange={this.onInputChange} value={username} title="Username" name="username" placeholder=""></AppTextInput>
                    <AppTextInput onChange={this.onInputChange} value={groupId} title="Gruppenkennung" name="groupId" placeholder=""></AppTextInput>
                    <AppTextInput onChange={this.onInputChange} type="code" value={code} title="SMS-Code" name="code" placeholder=""></AppTextInput>
                    <div className="errorMessage">
                        {errorMessage}
                    </div>
                </div>
                <AppButton onPress={this.onSignIn} title="login" color={AppButtonColor.PRIMARY}></AppButton>
                <div className="loginFormSeperator">- oder -</div>
                <AppButton onPress={this.onNextStep} title="registrieren" color={AppButtonColor.PRIMARY}></AppButton>
            </form>
        )
    }

    /*
      */
    renderRegistrationDetails() {

        const { errorMessage} = this.props.user;

        return (
            <form className="loginForm" name="loginForm" autoComplete="off">
               <div className="loginFormTitle">Bitte gib deine<br/>Daten ein:</div>
               <div className="loginFormFields">
                   <AppTextInput onChange={this.onInputChange} value={this.props.user.vorname} name="vorname" title="Vorname" placeholder=""></AppTextInput>
                   <AppTextInput onChange={this.onInputChange} value={this.props.user.nachname} name="nachname" title="Nachname" placeholder=""></AppTextInput>
                   <AppTextInput onChange={this.onInputChange} value={this.props.user.groupId} name="groupId" title="Gruppenkennung" placeholder=""></AppTextInput>
                   <div className="errorMessage">
                        {errorMessage}
                    </div>
               </div>
               <AppButton onPress={this.onPrevStep} title="<" color={AppButtonColor.SECONDARY}></AppButton>
                <div className="loginSperator"></div>     
               <AppButton onPress={this.onNextStep} title="weiter" color={AppButtonColor.PRIMARY}></AppButton>

           </form>
        
       )
    }

    renderRegistrationUsername() {

        const { errorMessage} = this.props.user;
        const { allowMessages, agreedDisclaimer } = this.state;
     
        return (
            <form className="loginForm" name="formDetails" autoComplete="off">
               <div className="loginFormTitle">Bitte gib deine<br/>Nutzerdaten ein:</div>
               <div className="loginFormFields">
                   <AppTextInput onChange={this.onInputChange} value={this.props.user.username} name="username" title="Username" placeholder=""></AppTextInput>
                   <AppTextInput onChange={this.onInputChange}  value={this.props.user.handynummer} name="handynummer" title="Handynummer<br/>inkl. internationaler Vorwahl<br/>z.B. 004366412345678" placeholder="z.B.: 004366412345678"></AppTextInput>
                   <Appcheckbox onPress={this.onCheck} name="disclaimer" checked={agreedDisclaimer} copy='Ich habe die Datenschutzerklärung gelesen und stimme dieser zu.'></Appcheckbox>
                   <Appcheckbox onPress={this.onCheck} name="messages" checked={allowMessages}  copy='Ich willige ein, dass die Webapplikation „DAS LOS“ des Theatervereins ANSICHT meine personenbezogenen Daten (Vorname und Nachname, Username, Telefonnummer) zu Zweck der Versendung von Informationen, die sich ausschließlich auf das Theaterspiel „DAS LOS“ beziehen, per SMS sowie zur Abfrage von Inhalten zur Gestaltung des Settings des Theaterspiels erhebt.'></Appcheckbox>
                   <div className="errorMessage">
                        {errorMessage}
                    </div>
               </div>
               <AppButton onPress={this.onPrevStep} title="<" color={AppButtonColor.SECONDARY}></AppButton>
               <div className="loginSperator"></div>  
               <AppButton onPress={this.onNextStep} title="weiter" color={AppButtonColor.PRIMARY}></AppButton>
              
           </form>
           
       )
    }

    renderRegistrationCode() {
        return (
            <form className="loginForm" name="formCode" autoComplete="off">
               <div className="loginFormTitle">Bitte gebe<br/>den Code ein:</div>
               <div className="loginFormCopy">Wir haben dir einen 3-stelligen Login-Code an deine Handynummer geschickt. Falls du diesen nicht erhalten hast, überprüfe, ob du die richtige Telefonnummer eingeben hast.</div>
               <div className="loginFormFields">
                   <AppTextInput onChange={this.onInputChange} name="code" value={this.props.user.code} title="Code" placeholder=""></AppTextInput>
               </div>
               <AppButton onPress={this.onPrevStep} title="<" color={AppButtonColor.SECONDARY}></AppButton>
               <div className="loginSperator"></div>  
               <AppButton onPress={this.onSignIn} title="weiter" color={AppButtonColor.PRIMARY}></AppButton>
              
           </form>
       )
    }

    render() {

     
        const { registerStep } = this.props.user;


        return(
            <div className="loginContainer">
                {registerStep===0 && this.renderLoginForm()}
                {registerStep===1 && this.renderRegistrationDetails()}
                {registerStep===2 && this.renderRegistrationUsername()}
                {registerStep===3 && this.renderRegistrationCode()}
                <div onClick={this.onDatenSchutz} className="loginDatenschutz">Datenschutzerklärung</div>
            </div>
        )
    }
    
};

const mapStateToProps = (state) => {
    return {
      user: state.user,
      auth: state.auth,
    };
  };

export default connect(mapStateToProps, {
    signIn,
    autoSignIn,
    onChangeUserInput,
    onRegisterNextStep,
    onRegister,
    onRegisterPrevStep,
    push,
})(Login)