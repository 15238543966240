
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import {UserReducer} from './reducers/UserReducer';
import {ForumReducer} from './reducers/ForumReducer';
import { AuthReducer } from './reducers/AuthReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user: UserReducer,
  forum: ForumReducer,
  auth: AuthReducer,
})
export default createRootReducer;
