import React, { Component } from 'react';
import { connect } from 'react-redux';
import { onHidePointsOverlay } from './../../actions/UserActions';
import './AppPointsOverlay.css';

class AppPointsOverlay extends Component {

    constructor(props) {
        super();
        this.hideUserScoreTimeout = 0;
    }

    componentWillUnmount() {
        clearTimeout(this.hideUserScoreTimeout);
    }

    componentDidUpdate(propsBefore) {
        
        if (this.props.user.showPointsOverlay !== propsBefore.user.showPointsOverlay) {
            if (this.props.user.showPointsOverlay) {

                this.hideUserScoreTimeout=setTimeout(this.hidePointsOverlay,1000);
            }
        }
    }

    hidePointsOverlay = () => {
        clearTimeout(this.hideUserScoreTimeout);
        this.props.onHidePointsOverlay();
    }

    /*
       if (showPointsOverlay) {
  
            return(
                <div className="appOverlayPoints">
                            <div className="appOverlayPointsContent">
                                +{pointsToShow}
                            </div>
                        </div>
             
               
            )} else {
            return (
                <div/>
            )}
    */

    render() {

        const { showPointsOverlay, pointsToShow} = this.props.user;
        return <div/>
        
     }
};

const mapStateToProps = (state) => {
    return {
      user: state.user,
    };
  };
  
  
  export default connect(mapStateToProps,{
    onHidePointsOverlay
  })(AppPointsOverlay);