import React, { Component } from 'react';
import './../../globals/styles.css';
import {eraseCookie} from './../../globals/helpers';
import AppButtonIcon, { AppButtonIconType } from './AppButtonIcon';
import './AppNavigationHeader.css';


export default class AppNavigationHeader extends Component {

    
    goBack = () => {
        const { goHome = false } = this.props
        if (goHome) {
            this.props.history.push('/');
        } else {
            this.props.history.goBack()
        }
    }
    onHelp = () => {
        window.location.href = "mailto:office@theateransicht.at?subject=Frage%20zum%20Spiel";
    }

    onLogout = () => {
        eraseCookie('accesstoken');
        window.location.reload();
    }


    render() {
       
        const { title } = this.props
        return(
            <div className="appNavigationHeaderContainer">
                <div className="appNavigationHeaderBack"><AppButtonIcon onPress={this.goBack} icon={AppButtonIconType.BACK}></AppButtonIcon></div>
                <div className="appNavigationHeaderLogout"><AppButtonIcon onPress={this.onLogout} icon={AppButtonIconType.LOGOUT}></AppButtonIcon></div>
                <div onClick={this.onHelp} className="appNavigationHeaderHelp">?</div>
                <div className="appNavigationHeaderTitle"> - </div>
                <div className="appNavigationHeaderTitle">{title}</div>
            </div>
        )
    }

};
