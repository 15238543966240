import { push } from 'connected-react-router'
import { objectToQueryString } from '../globals/helpers';
import { API_URL } from './../globals/globals'
import {
  FORUM_GET_STATEMENTS,
  FORUM_GET_STATEMENTS_SUCCESS,
  FORUM_GET_STATEMENTS_ERROR,
  FORUM_POST_STATEMENT,
  FORUM_POST_STATEMENT_SUCCESS,
  FORUM_POST_STATEMENT_ERROR,
  FORUM_GET_COMMENTS,
  FORUM_GET_COMMENTS_ERROR,
  FORUM_GET_COMMENTS_SUCCESS,
  FORUM_INPUT_CHANGE, 
  FORUM_POST_COMMENT_ERROR, 
  FORUM_POST_COMMENT, 
  FORUM_POST_COMMENT_SUCCESS,
  LIKE_POST,
  LIKE_POST_ERROR,
  LIKE_POST_SUCCESS, 
  DISLIKE_POST_ERROR, 
  DISLIKE_POST_SUCCESS, 
  DISLIKE_POST, 
  USER_SHOW_POINTS, 
  FORUM_CHECKVOTING, 
  FORUM_CHECKVOTING_ERROR, 
  FORUM_CHECKVOTING_SUCCESS,
  VOTE,
  VOTE_ERROR,
  VOTE_SUCCESS,
  SELECT_WINNERSTATEMENT,
  SELECT_WINNERSTATEMENT_ERROR,
  SELECT_WINNERSTATEMENT_SUCCESS,
  TOPIC_GET_RESULTS,
  TOPIC_GET_RESULTS_ERROR,
  TOPIC_GET_RESULTS_SUCCESS,
  FORUM_GET_TOPICS,
  FORUM_GET_TOPICS_ERROR,
  FORUM_GET_TOPICS_SUCCESS,
} from './types';

export const onChangeForumInput = ({name, value}) => {
    return {type: FORUM_INPUT_CHANGE, payload: {name: name, value: value}};
}

export const onLike = ({objectId, accesstoken}) => {
  return (dispatch) => {
    dispatch({type: LIKE_POST, payload: {}});

    const postData = {
      action: 'like',
      //uid: userId,
      oid: objectId,
      //gid: groupId,
      accesstoken,
    }

    const queryString = objectToQueryString(postData);
    console.log(queryString);

    fetch(API_URL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())
        } else {
            dispatch({type: LIKE_POST_ERROR, payload: 'Fehler'});
            return []
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson !== []) {
            if (responseJson.response.result==="success") {
                dispatch({type: LIKE_POST_SUCCESS, payload: {objectId: objectId}});
                dispatch({type: USER_SHOW_POINTS, payload: responseJson.data.score.delta});
            } else {
              console.log('ERROR: ' + responseJson.response.msg);
                dispatch({type: LIKE_POST_ERROR, payload: responseJson.response.msg});
            }
        } else {
          dispatch({type: LIKE_POST_ERROR, payload: 'Fehler'});
        }
        
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: LIKE_POST_ERROR, payload: 'Verbindungsfehler'});
      });

  }

}

export const onDislike = ({objectId, accesstoken}) => {
  return (dispatch) => {

    dispatch({type: DISLIKE_POST, payload: {}});

    const postData = {
      action: 'dislike',
      //uid: userId,
      oid: objectId,
      //gid: groupId,
      accesstoken
    }

    const queryString = objectToQueryString(postData);
    console.log(queryString);

    fetch(API_URL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())
        } else {
            dispatch({type: DISLIKE_POST_ERROR, payload: 'Fehler'});
            return []
        }
      })
      .then((responseJson) => {
       
        if (responseJson !== []) {
            if (responseJson.response.result==="success") {
                dispatch({type: DISLIKE_POST_SUCCESS, payload: {objectId: objectId}});
                dispatch({type: USER_SHOW_POINTS, payload: responseJson.data.score.delta});
            } else {
              console.log('ERROR: ' + responseJson.response.msg);
                dispatch({type: DISLIKE_POST_ERROR, payload: responseJson.response.msg});
            }
        } else {
          dispatch({type: DISLIKE_POST_ERROR, payload: 'Fehler'});
        }
        
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: DISLIKE_POST_ERROR, payload: 'Verbindungsfehler'});
      });

  }

}

export const getStatements = ({topicId, accesstoken}) => {
  return (dispatch) => {
    dispatch({type: FORUM_GET_STATEMENTS, payload: {}});

    const postData = {
        action: 'getStatements',
        //gid: groupId,
        tid: topicId,
        //uid: userId,
        accesstoken
    }

    const queryString = objectToQueryString(postData)
    console.log(queryString);

    fetch(API_URL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())
        } else {
            dispatch({type: FORUM_GET_STATEMENTS_ERROR, payload: 'Fehler'});
            return []
        }
      })
      .then((responseJson) => {
       
        if (responseJson !== []) {
          if (responseJson.response.result==="success") {
              dispatch({type: FORUM_GET_STATEMENTS_SUCCESS, payload: responseJson.data});
          } else {
            console.log('ERROR: ' + responseJson.response.msg);
              dispatch({type: FORUM_GET_STATEMENTS_ERROR, payload: responseJson.response.msg});
          }
        } else {
          dispatch({type: FORUM_GET_STATEMENTS_ERROR, payload: 'Fehler'});
        }
        
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: FORUM_POST_COMMENT_ERROR, payload: 'Verbindungsfehler'});
      });

  }
};

export const getComments = ({statementId, accesstoken}) => {
    return (dispatch) => {
      dispatch({type: FORUM_GET_COMMENTS, payload: {}});

      const postData = {
        action: 'getComments',
        //gid: groupId,
        //uid: userId,
        sid: statementId,
        accesstoken
      }
  
      const queryString = objectToQueryString(postData);
      console.log(queryString);

      fetch(API_URL,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: queryString,
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
              return(response.json())
          } else {
              dispatch({type: FORUM_GET_COMMENTS_ERROR, payload: 'Fehler'});
              return []
          }
        })
        .then((responseJson) => {
         
          if (responseJson !== []) {
            if (responseJson.response.result==="success") {
                dispatch({type: FORUM_GET_COMMENTS_SUCCESS, payload: {statement: responseJson.data.statement, comments: responseJson.data.comments}});
            } else {
              console.log('ERROR: ' + responseJson.response.msg);
                dispatch({type: FORUM_GET_COMMENTS_ERROR, payload: responseJson.response.msg});
            }
          } else {
            dispatch({type: FORUM_GET_COMMENTS_ERROR, payload: 'Fehler'});
          }
          
        })
        .catch((error) => {
          console.log(error);
          dispatch({type: FORUM_POST_COMMENT_ERROR, payload: 'Verbindungsfehler'});
        });
  
    }
};

export const postStatement = ({topicId, post, accesstoken}) => {
    return (dispatch) => {
      dispatch({type: FORUM_POST_STATEMENT, payload: {}});

      const postData = {
          action: 'postStatement',
          //gid: groupId,
          //uid: userId,
          tid: topicId,
          statement: post,
          accesstoken
      }

      const queryString = objectToQueryString(postData)
      console.log(queryString);
   
      fetch(API_URL,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: queryString,
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
              console.log('OH');
              return(response.json())
          } else {
              dispatch({type: FORUM_POST_STATEMENT_ERROR, payload: 'Fehler'});
              return []
          }
        })
        .then((responseJson) => {
            console.log(responseJson);
          if (responseJson !== []) {
            if (responseJson.response.result==="success") {
                dispatch({type: FORUM_POST_STATEMENT_SUCCESS, payload: responseJson.data.statements});
                dispatch({type: USER_SHOW_POINTS, payload: responseJson.data.score.delta});
            } else {
              console.log('ERROR: ' + responseJson.response.msg);
                dispatch({type: FORUM_POST_STATEMENT_ERROR, payload: responseJson.response.msg});
            }
          } else {
            dispatch({type: FORUM_POST_STATEMENT_ERROR, payload: 'Fehler'});
          }
          
        })
        .catch((error) => {
          console.log(error);
          dispatch({type: FORUM_POST_COMMENT_ERROR, payload: 'Verbindungsfehler'});
        });
  
    }
};

export const postComment = ({statementId, post, accesstoken}) => {
    return (dispatch) => {
      dispatch({type: FORUM_POST_COMMENT, payload: {}});


      const postData = {
          action: 'postComment',
          //gid: groupId,
          //uid: userId,
          sid: statementId,
          comment: post,
          accesstoken,
      }

      const queryString = objectToQueryString(postData);

      console.log(queryString);
   
      fetch(API_URL,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: queryString
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
              console.log('OH');
              return(response.json())
          } else {
              dispatch({type: FORUM_POST_COMMENT_ERROR, payload: 'Fehler'});
              return []
          }
        })
        .then((responseJson) => {
            console.log(responseJson);
          if (responseJson !== []) {
            if (responseJson.response.result==="success") {
                dispatch({type: FORUM_POST_COMMENT_SUCCESS, payload: responseJson.data.comments});
                dispatch({type: USER_SHOW_POINTS, payload: responseJson.data.score.delta});
            } else {
              console.log('ERROR: ' + responseJson.response.msg);
                dispatch({type: FORUM_POST_STATEMENT_ERROR, payload: responseJson.response.msg});
            }
          } else {
            dispatch({type: FORUM_POST_COMMENT_ERROR, payload: 'Fehler'});
          }
          
        })
        .catch((error) => {
          console.log(error);
          dispatch({type: FORUM_POST_COMMENT_ERROR, payload: 'Verbindungsfehler'});
        });
  
    }
};

export const checkForVoting = ({accesstoken}) => {


  return (dispatch) => {
    dispatch({type: FORUM_CHECKVOTING, payload: {}});

    const postData = {
      action: 'checkForVoting',
      //uid: uid,
      //gid: gid,
      accesstoken,
  }

  console.log('CHECK FOR VOTING');
  const queryString = objectToQueryString(postData)
  console.log(queryString);

    fetch(API_URL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())


        } else {
            dispatch({type: FORUM_CHECKVOTING_ERROR, payload: 'Fehler'});
            return []
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson !== []) {
          if (responseJson.response.result==="success") {
            dispatch({type: FORUM_CHECKVOTING_SUCCESS, 
              payload: {
                isVoting: true,
                users: responseJson.data.users,
                winner: responseJson.data.winner,
                statements: responseJson.data.statements,
                topic: responseJson.data.topic,
              }
            });
            dispatch(push('/voting'))
          } else {
            dispatch({type: FORUM_CHECKVOTING_ERROR,
              payload: {
                isVoting: false,
                users: [],
                statements: [],
                winner: null,
                topic: null,
              }
            });
            dispatch(push('/saeulenhalle'));
        
          }
        } else {
          dispatch({type: FORUM_CHECKVOTING_ERROR, payload: 'Fehler'});
        }
        
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: FORUM_CHECKVOTING_ERROR, payload: 'Verbindungsfehler'});
      
      });

  }
};

export const getTopics = ({accesstoken}) => {


  return (dispatch) => {
    dispatch({type: FORUM_GET_TOPICS, payload: {}});

    const postData = {
      action: 'getTopics',
      //gid: gid,
      accesstoken,
  }

  const queryString = objectToQueryString(postData)
  console.log(queryString);

    fetch(API_URL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())


        } else {
            dispatch({type: FORUM_GET_TOPICS_ERROR, payload: 'Fehler'});
            return []
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson !== []) {
          if (responseJson.response.result==="success") {
            dispatch({type: FORUM_GET_TOPICS_SUCCESS, 
              payload: {
                isVoting: true,
                topics: responseJson.data.topics,
               }
            });
          
          }
        } else {
          dispatch({type: FORUM_GET_TOPICS_ERROR, payload: 'Fehler'});
        }
        
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: FORUM_GET_TOPICS_ERROR, payload: 'Verbindungsfehler'});
      
      });

  }
};

export const vote = ({topicId, vote, accesstoken}) => {
  return (dispatch) => {
    dispatch({type: VOTE, payload: {}});

    const postData = {
      action: 'vote',
      //uid: userId,
      //gid: groupId,
      tid: topicId,
      vote: vote,
      accesstoken
    }

    const queryString = objectToQueryString(postData);
    console.log(queryString);

    fetch(API_URL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())
        } else {
            dispatch({type: VOTE_ERROR, payload: 'Fehler'});
            return []
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson !== []) {
            if (responseJson.response.result==="success") {
                dispatch({type: VOTE_SUCCESS});
                dispatch({type: USER_SHOW_POINTS, payload: responseJson.data.score.delta});
                dispatch(push('/saeulenhalle'));
            } else {
                dispatch({type: VOTE_SUCCESS});
                dispatch(push('/saeulenhalle'));
            }
        } else {
          dispatch({type: VOTE_ERROR, payload: 'Fehler'});
        }
        
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: VOTE_ERROR, payload: 'Verbindungsfehler'});
      });

  }

}

export const selectWinnerStatement = ({topicId, sid, accesstoken}) => {
  return (dispatch) => {
    dispatch({type: SELECT_WINNERSTATEMENT, payload: {}});

    const postData = {
      action: 'selectWinnerStatement',
      //uid: userId,
      //gid: groupId,
      tid: topicId,
      sid: sid,
      accesstoken,
    }

    const queryString = objectToQueryString(postData);
    console.log(queryString);

    fetch(API_URL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())
        } else {
            dispatch({type: SELECT_WINNERSTATEMENT_ERROR, payload: 'Fehler'});
            return []
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson !== []) {
            if (responseJson.response.result==="success") {
                dispatch({type: SELECT_WINNERSTATEMENT_SUCCESS});
                dispatch(push('/saeulenhalle'));
            } else {
                dispatch({type: SELECT_WINNERSTATEMENT_SUCCESS});
                dispatch(push('/saeulenhalle'));
            }
        } else {
          dispatch({type: SELECT_WINNERSTATEMENT_ERROR, payload: 'Fehler'});
        }
        
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: SELECT_WINNERSTATEMENT_ERROR, payload: 'Verbindungsfehler'});
      });

  }

}

export const getTopicResults = ({topicId, accesstoken}) => {
  return (dispatch) => {
    dispatch({type: TOPIC_GET_RESULTS, payload: {}});

    const postData = {
      action: 'getTopicResults',
      //uid: userId,
      //gid: groupId,
      tid: topicId,
      accesstoken
    }

    const queryString = objectToQueryString(postData);
    console.log(queryString);

    fetch(API_URL,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())
        } else {
            dispatch({type: TOPIC_GET_RESULTS_ERROR, payload: 'Fehler'});
            return []
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson !== []) {
                dispatch({type: TOPIC_GET_RESULTS_SUCCESS, payload: responseJson.data});
                dispatch(push('/saeulenhalle/results/' + topicId));
           
        } else {
          dispatch({type: TOPIC_GET_RESULTS_ERROR, payload: 'Fehler'});
        }
        
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: TOPIC_GET_RESULTS_ERROR, payload: 'Verbindungsfehler'});
      });

  }

}