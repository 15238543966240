import React, { Component } from 'react';
import { push } from 'connected-react-router'
import AppButton, { AppButtonColor } from '../ui/AppButton';
import AppScore from '../ui/AppScore';
import './Home.css';
import './../../globals/styles.css';
import { connect } from 'react-redux';
import AppButtonIcon, {AppButtonIconType} from '../ui/AppButtonIcon';
import {eraseCookie} from './../../globals/helpers';


class Home extends Component {


    constructor(props) {
        super();
    }

    gotoForum = () => {
      const {hasAnsweredQuestions} = this.props.user;
      if (hasAnsweredQuestions) {
        this.props.push('/saeulenhalle');
      } else {
        this.props.push('/fragen');
      }
    }

    onDatenSchutz = () => {
      this.props.push('/datenschutz')
  }
      
      

    gotoRegeln = () => {
      this.props.push('/regeln');
   }

   onHelp = () => {
    window.location.href = "mailto:office@theateransicht.at?subject=Frage%20zum%20Spiel";
  }  

  onLogout = () => {
    eraseCookie('accesstoken');
    window.location.reload();
  }


    componentDidMount() {
      window.scrollTo(0, 0);
    }

    render () {

      const {position} = this.props.user;

      const positionText = position === 1 ? 'meisten' : position + '. meisten';

      //  <div className="homeCopy">Du hast derzeit am {positionText} Punkte von allen Teilnehmer*innen. Diskutiere mit und erhalte mehr Punkte.</div>
          
      

        return(
         <div className="homePage">
          
             
            <div className="homeHeadline">Reboot<br/>the system</div>
            <div className="homeCopy">Du bist auserwählt unsere Welt neu zu gestalten. Nur wenige haben diese Möglichkeit, nutze sie weise.
            Danke für Deinen Einsatz, aber bedenke: alles hat seine Konsequenzen.</div>
            <AppButton onPress={this.gotoForum} title="zum Spiel>" color={AppButtonColor.PRIMARY}></AppButton><br/><br/>
            <AppButton onPress={this.gotoRegeln} title="Regeln?" color={AppButtonColor.SECONDARY}></AppButton><br/><br/>
            <div><AppScore/></div><br/>
          
            <div onClick={this.onHelp} className="homeHelp">Hilfe</div><br/>
            <div className="homeLogoutContainer">
              <AppButtonIcon onPress={this.onLogout} icon={AppButtonIconType.LOGOUT}></AppButtonIcon><div className="homeLogout">Logout</div>
            </div>
            <div onClick={this.onDatenSchutz} className="homeDatenschutz">Datenschutzerklärung</div>
          
         </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log(ownProps);
    return {
      user: state.user
    };
  };

export default connect(mapStateToProps,{push})(Home);