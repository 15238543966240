import {AUTH_SIGNIN, AUTH_SIGNOUT, AUTH_SIGNIN_ERROR, AUTH_SIGNIN_SUCCESS, AUTH_AUTOSIGNIN_ERROR} from './../actions/types';

const INITIAL_STATE = {
  isLoading: false,
  isSignedIn: false,
  errorMessage: '',
 
};

export const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_SIGNIN:
       
      return {...state, 
        errorMessage: '',
        isSignedIn: false,
       isLoading: true,
      }
      case AUTH_SIGNIN_SUCCESS:
        console.log('EINGELOOGT AUTH REDUCER');
        return {...state, 
            errorMessage: '',
            isLoading: false,
            isSignedIn: true,
        }
        case AUTH_AUTOSIGNIN_ERROR:
          return {...state, 
            isLoading: false,
            errorMessage: action.payload,
            isSignedIn: false,
        }
        case AUTH_SIGNIN_ERROR:
          console.log('NICHT EINGELOOGT REDUCER');
            return {...state, 
                isLoading: false,
                errorMessage: action.payload,
                isSignedIn: false,
            }
      case AUTH_SIGNOUT:
        return {...state, 
            errorMessage: '',
            isLoading: false,
            isSignedIn: false,
        }
   
      
    default:
      return state;
  }
};
