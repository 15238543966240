import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import AppButton, { AppButtonColor } from '../ui/AppButton';
import './Fragen.css';
import './../../globals/styles.css';
import { setFragen } from '../../actions/UserActions';

class Fragen extends Component {

    componentDidMount() {
      window.scrollTo(0, 0);
    }

    state = {
        fragenIndex: 0,
        fragen: ["Übernimmst du gerne Verantwortung?","Legst Du Wert auf die Meinung anderer?"],
        answers: [],
    }

    answer = (value) => {

        const tmpAnswers = this.state.answers;
        tmpAnswers.push(value)

        this.setState({
            fragenIndex: this.state.fragenIndex + 1,
            answers: tmpAnswers,
        })

        if (this.state.fragenIndex >= this.props.forum.fragen.length - 1) {
            //this.props.push('/saeulenhalle');
            this.props.setFragen({accesstoken: this.props.user.accesstoken, fragen: this.state.answers})
        }

    }

    render () {

        const { fragen } = this.props.forum;

        console.log(this.state.fragenIndex);
        console.log(fragen.length);

        return(
         <div className="fragenPage">
         {this.state.fragenIndex < fragen.length && (
            <div>
                <div className="fragenHeadline">Reboot<br/>the system</div>
                <div className="fragenCopy">{fragen[this.state.fragenIndex]}</div>
            
                <AppButton onPress={()=>this.answer(0)} title="Ja" color={AppButtonColor.PRIMARY}></AppButton>
                <div className="fragenSeperator">- oder -</div>
                <AppButton onPress={()=>this.answer(1)} title="Nein" color={AppButtonColor.SECONDARY}></AppButton>
            </div>
            )}
         </div>
         
        )
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.user,
      forum: state.forum,
    };
  };

export default connect(mapStateToProps,{
    push,
    setFragen
})(Fragen);