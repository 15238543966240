import React, { Component } from 'react';
import { withRouter } from "react-router";
import './Datenschutz.css';
import './../../globals/styles.css';
import { connect } from 'react-redux';
import AppNavigationHeader from '../ui/AppNavigationHeader';


class Datenschutz extends Component {


    constructor(props) {
        super();
    }

    gotoHome = () => {
        this.props.history.push('/');
     }


    render () {

   
        return(
         <div className="datenschutzPage">
           <AppNavigationHeader goHome history={this.props.history} title="Daten"></AppNavigationHeader>
               
            <div className="datenschutzHeadline">Datenschutz</div>
            <div className="datenschutzCopy">
            <strong> Datenschutz ist uns wichtig.</strong><br/>
            <br/>
            Wir informieren daher an dieser Stelle, welche personenbezogenen Daten (nachfolgend „Daten“ genannt) der Theaterverein ANSICHT bei der Web-Applikation zum Theaterprojekt „DAS LOS“ abfragt und warum (Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung).<br/><br/>
            Um ein besonderes Spielerlebnis zu ermöglichen, beinhaltet das Theaterstück „DAS LOS“ eine eigene Web-Applikation. Die Benutzung dieser ist nur nach einer Registrierung möglich. Drei Tage vor der Aufführung kann sich das Publikum in die Web-Applikation (in Folge „Web-App“) einloggen. <br/><br/>
            Im Zuge der Benützung dieser Web-App werden wir folgende Informationen erheben, die nur im Rahmen der Erforderlichkeit für das Theaterspiel verarbeitet werden:<br/><br/>
            Das Datum und die Uhrzeit des Aufrufs unserer Web-App sowie jene Informationen, die durch die Registrierung zur Verfügung gestellt werden. Das sind: Vorname und Nachname, Username, Telefonnummer.<br/><br/>
            Zu folgenden Zwecken werden Daten verarbeitet: Um den Teilnehmer_innen diese Web-App zur Verfügung zu stellen, ihnen per SMS (via www.websms.at) spielrelevante Informationen zu senden, die sich ausschließlich auf das Theaterspiel „DAS LOS“ beziehen. Zudem ist es Teil des Spiels, auf unserer Web-App Fragen, Antworten, Meinungen oder Bewertungen, nachfolgend nur „Beiträge“ genannt, zu veröffentlichen. Sofern dieses Angebot in Anspruch genommen wird, verarbeiten und veröffentlichen wir innerhalb der Web-App den Beitrag sowie den genutzten Usernamen.<br/> <br/>
            Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO. Die Einwilligung können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen.<br/><br/>
            Wenn Sie auf dieser Website personenbezogene Daten eingeben, erteilen Sie uns mit der Eingabe Ihrer Daten die Zustimmung, dass wir die Telefonnummer angegebenen Zweck elektronisch verwenden dürfen. Diese Daten werden sicher verwahrt und nur an websms weitergegeben. Davon ausgenommen ist ebenfalls die Weitergabe an staatliche Behörden, sofern der Verein gesetzlich dazu verpflichtet ist. Es besteht keine Verpflichtung, jene Daten, um deren Angabe wir Sie auf unserer Website bitten, tatsächlich anzugeben. Wenn Sie dies jedoch nicht tun, wird es Ihnen nicht möglich sein, alle Funktionen der Web-App zu nutzen.<br/><br/>
            Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in diese Verarbeitung eingeholt und auf diese Datenschutzerklärung verwiesen. Die dabei von uns erhobenen Daten werden ausschließlich für die Zurverfügungstellung der Web-App verwendet. Soweit Sie in diese Verarbeitung einwilligen, ist Art. 6 Abs. 1 lit. a) DSGVO Rechtsgrundlage für die Verarbeitung.<br/><br/>
            Die bei Nutzung unserer Applikation verarbeiteten Daten werden gelöscht, sobald der Zweck der Speicherung entfällt und zwar nach der Theateraufführung bzw. 5 Tage nach der Registrierung.<br/><br/>
            <br/><br/>
            <strong>Cookies</strong><br/>
            <br/>
            Auf dieser Website werden sogenannte Cookies verwendet. Ein Cookie ist eine Datei, die auf Ihrem Endgerät gespeichert werden kann, wie Sie eine Website besuchen. Grundsätzlich werden Cookies verwendet, um NutzerInnen zusätzliche Funktionen auf einer Website zu bieten. Sie können zum Beispiel verwendet werden, um Ihnen die Navigation auf der Website zu erleichtern, es Ihnen zu ermöglichen, eine Website dort weiter zu verwenden, wie Sie sie verlassen haben und/oder Ihre Präferenzen und Einstellungen zu speichern, wenn Sie die Website wieder besuchen. Cookies können auf keine anderen Daten auf Ihrem Endgerät zugreifen, diese lesen oder verändern. Wenn Sie Cookies nicht akzeptieren möchten, ändern Sie bitte Ihre Browsereinstellung entsprechend. Beachten Sie, dass dadurch Funktionalitäten der Website eingeschränkt werden können.<br/><br/>
            Übermittlung Ihrer personenbezogenen Daten an Dritte<br/><br/>
            Wir setzen zur sicheren Verarbeitung Ihrer personenbezogenen Daten sowie auf unserer Website auch Programme und Tools von Drittanbietern ein, mit welchen wir Auftragsverarbeitungsvereinbarungen abgeschlossen und damit sichergestellt haben, dass auch diese den Schutz Ihrer personenbezogenen Daten im Sinne der DSGVO gewährleisten. Dies betrifft folgende Anbieter:<br/><br/>
            websms.at<br/><br/>
            <br/>
            <strong>Ihre Rechte im Zusammenhang mit Ihren personenbezogenen Daten</strong><br/><br/>
            Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch zu. Dafür wenden Sie sich an uns: Theaterverein Ansicht, Salmannsdorferstr. 3/2, 1190 Wien, office@theateransicht.at. Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, haben Sie die Möglichkeit sich an die Datenschutzbehörde zu wenden.<br/><br/>
            Urheberrecht<br/>
            Der Inhalt dieser Homepage ist urheberrechtlich geschützt. Die Informationen sind nur für die persönliche Verwendung bestimmt. Jede weitergehende Nutzung- insbesondere die Speicherung in Datenbanken, die Vervielfältigung und jede Form von gewerblicher Nutzung sowie die Weitergabe an Dritte (auch in Teilen oder in überarbeiteter Form) - ohne Zustimmung des Vereins ist untersagt.<br/><br/>
            Haftungsausschluss<br/>
            Es kann keine Gewähr für die Authentizität, Richtigkeit und Vollständigkeit der im Internet zur Verfügung gestellten Informationen übernommen werden. Des Weiteren wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb der gegenständlichen Web-App und ihrer Inhalte übernommen. Jede Haftung für unmittelbare, mittelbare oder sonstige Schäden, unabhängig von deren Ursachen, die aus der Benutzung oder Nichtverfügbarkeit der Daten und Informationen dieser Homepage erwachsen, wird, soweit rechtlich zulässig, ausgeschlossen.<br/> <br/>
            <br/>
            <strong>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</strong><br/>
            <br/>
            Theaterverein ANSICHT<br/>
            Salmannsdorferstraße 3/2<br/>
            1190 Wien <br/>
            <br/>
            <a href="mailto:office@theateransicht.at">E-Mail: office@theateransicht.at</a><br/>

            </div>
           

         </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user: state.user
    };
  };

export default withRouter(connect(mapStateToProps,{})(Datenschutz));