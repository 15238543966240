import React from 'react';
import './AppButton.css';

export const AppButtonColor = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    WHITE: 'white',
    ALERT: 'alert',
    TRANSPARENT: 'transparent',
}

export default function AppButton ({onPress, title, color, isDisabled}) {

    return(
        <div onClick = {onPress} className={`appButtonContainer ${color}`}>
            <div className="appButtonTitle">{title}</div>
        </div>
    )

};
