import React from 'react';
import './TopicResult.css';


export default function TopicResult ({amount, title, size, linebreak}) {

    return (
            <div className={`topicResultContainer ${size} topicResultLinebreak`}>
                <div className={`topicResultAmount ${size}`}>{amount}</div>
                <div className={`topicResultTitle ${size}`}>{title}</div>
            </div>
           

        
    )

};
