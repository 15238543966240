import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { selectWinnerStatement} from './../../actions/ForumActions';
import AppButton, { AppButtonColor } from './../ui/AppButton';
import './../../globals/styles.css';
import './Voting.css';
import Statement from '../forum/Statement';

class VotingFrage extends Component {

    constructor(props) {
        super();
        this.state = {
            selectedStatement: null,
        }
    }

    selectStatement = (statement) => {
        console.log('SELECTED STATEMENT ' + statement.sid);
        this.setState({
            selectedStatement: statement,
        })
    }

    submitSelectedStatemenet = () => {
        this.props.selectWinnerStatement({
            accesstoken: this.props.user.accesstoken,
            topicId: this.props.forum.votingTopic.id,
            sid: this.state.selectedStatement.sid,
        })
    }

    renderSelect(statements, selectedStatement) {
        return(
            <div className="votingStatementsList">
             {statements.map((statement, index) => {
               let showBackground;
                index%2 === 0 ? showBackground = true : showBackground = false;
                return (
                    <Statement onPress={this.selectStatement} key={index} data={statement} selected={selectedStatement && selectedStatement.sid === statement.sid} showBackground={showBackground}></Statement>
                )
                })}
            </div> 
        );
    }

    render() {

        const {votingTopic, votingStatements} = this.props.forum;
        const {selectedStatement} = this.state;

        return (
            <div className="page voting">
            {votingTopic && (
                <div>
              <div className="votingHeader">
                        <div className="votingHeadline">Losentscheid</div>
                        <div className="votingTopic">{votingTopic.title}</div>
                    </div>
                <div className="votingContainer">
                    <div className="votingImpulseContainer">
                    <div className="votingImpulseContent">{votingTopic.impulse}</div>
                </div>
                <div className="votingCopy">Wähle einen Vorschlag</div>
                  
                    {this.renderSelect(votingStatements, selectedStatement)}
                </div>
                {selectedStatement && (
                <div className="toolbar">
                      <AppButton onPress={this.submitSelectedStatemenet} title="ok" color={AppButtonColor.PRIMARY}></AppButton>
                </div>
                )}
                </div>
                )}
           </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
      user: state.user,
      forum: state.forum,
    };
  };

export default withRouter(connect(mapStateToProps,{
    selectWinnerStatement
})(VotingFrage));