import React, { Component } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import Topic, { topicState } from './Topic';
import './../../globals/styles.css';
import './Saeulenhalle.css';
import TopicTimer from './TopicTimer';
import AppScore from '../ui/AppScore';
import AppOverlay from '../ui/AppOverlay';
import {checkForVoting, getTopicResults, getTopics} from './../../actions/ForumActions';
import AppNavigationHeader from '../ui/AppNavigationHeader';


class  Saeulenhalle extends Component {


    constructor(props) {
        super();
        this.state = {
            overlayIsVisible: false,
            overlayMessage: '',
            overlayTitle: '',
            scale: 1,
        }
        this.updateInterval = 0;
    }

    componentDidMount() {
        console.log("--------> Säulenhalle Mounted")
        this.updateTopics();
        this.updateInterval = setInterval(this.updateTopics, 5000)
        this.props.checkForVoting({accesstoken: this.props.user.accesstoken})
        window.scrollTo(0, 0);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
     
      }
    
    componentWillUnmount() {
        console.log('UNMOUNT SAULENHALLE');
        clearInterval(this.updateInterval);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {

       let scale = 1;
       const p = Window.devicePixelRatio || 1
       const w = Math.ceil(window.innerWidth / p);
        console.log(w);
       if (w<=400) scale = 0.75;

       this.setState({
           scale,
       })
      

    }

    updateTopics = () => {
        this.updateWindowDimensions();
        this.props.getTopics({accesstoken: this.props.user.accesstoken});
    }

    goHome = () => {
        this.props.history.push('/');
      }

    onPressTopic = (id, title, deadline, state) => {
        console.log('CLICKED: ' + title + ' mit der ID ' + id) ;
        const deltaMS = deadline - new Date()
        const isOpen = deltaMS > 0
        const hours = Math.floor(deltaMS/1000/60/60);
        const minutes = Math.ceil((deltaMS/1000/60)%60);

        console.log(state);
  
        switch (state) {

            case topicState.OPEN: 
                if (isOpen) {
                    this.props.history.push('/saeulenhalle/'+id);
                }
                break;
            case topicState.LOCKED:
                this.setState({
                    overlayTitle: 'Das Forum zum Thema ' + title + ' ist noch geschlossen.',
                    overlayMessage: 'Komme in ' + hours + ' Stunden und ' + minutes + ' Minuten wieder und diskutiere mit.',
                    overlayIsVisible: true,
                })
                break; 
            case topicState.VOTING: 
                    this.setState({
                        overlayTitle: 'Das Los ist gefallen.',
                        overlayMessage: 'Ein/e zufällig bestimmte/r SpielerIn wählt einen Vorschlag. Komme in ' + hours + ' Stunden und ' + minutes + ' Minuten wieder und entscheide, ob die mit der Wahl einverstanden bist.',
                        overlayIsVisible: true,
                    })
                break;
            case topicState.VOTED: 
                this.props.getTopicResults({accesstoken: this.props.user.accesstoken, topicId: id})
                break;
            default:
                break;
        }
    }

   

    onCloseOverlay = () => {
        this.setState({
            overlayIsVisible: false
        })
    }
    render() { 

        const { overlayTitle, overlayMessage, overlayIsVisible, scale } = this.state;
        const { deadline, groupname } = this.props.user;
        const { topics } = this.props.forum;

       


        return (

         
            <div className="sauelenhallePage">
                 <AppNavigationHeader goHome history={this.props.history} title="Themen"></AppNavigationHeader>
                <AppOverlay title={overlayTitle} msg={overlayMessage} isVisible={overlayIsVisible} onClose={this.onCloseOverlay}></AppOverlay>
                <div>
                {topics.map((topic, index) => {
                     return (
                        <Topic key={index} onPress={this.onPressTopic} scale={scale} okCount={topic.okCount} vetoCount={topic.vetoCount} commentsCount={topic.commentsCount} statementsCount={topic.statementsCount} id={topic.id} position={index} state={topic.state} deadline={new Date(topic.deadline)} title={topic.title}></Topic>
                    );
                })}
                </div>
                <div className="toolbar">
                    <div className="toolbarCol toolbarCol1">
                    {groupname}
                    </div>
                    <div className="toolbarCol toolbarCol2">
                    <AppScore/>
                    </div>
                    <div className="toolbarCol toolbarCol3">
                       <TopicTimer showDetails deadline={deadline}></TopicTimer>
                    </div>
              
                    
                </div>
            </div>

        )
    }
};

const mapStateToProps = (state) => {
    return {
      user: state.user,
      forum: state.forum,
    };
  };

export default withRouter(connect(mapStateToProps,{
    checkForVoting,
    getTopicResults,
    getTopics,
})(Saeulenhalle));