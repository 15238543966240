export const  USER_LOGIN_ERROR = 'user_login_error';
export const USER_LOGIN = 'user_login';
export const USER_LOGIN_SUCCESS = 'user_login_success';
export const USER_INPUT_CHANGE = 'user_input_change';

export const USER_REGISTER = 'user_register';
export const USER_REGISTER_ERROR = 'user_register_error';
export const USER_REGISTER_SUCCESS = 'user_register_succes';

export const USER_REGISTER_NEXT = 'user_register_next';
export const USER_REGISTER_PREV = 'user_register_prev';

export const USER_ADD_SCORE = 'user_add_score';

export const FORUM_SETUP = 'forum_setup';

export const FORUM_GET_TOPICS = "forum_get_topics";
export const FORUM_GET_TOPICS_ERROR = "forum_get_topics_error";
export const FORUM_GET_TOPICS_SUCCESS = "forum_get_topics_success";

export const FORUM_CHECKVOTING = "forum_checkvoting";
export const FORUM_CHECKVOTING_ERROR = "forum_checkvoting_error";
export const FORUM_CHECKVOTING_SUCCESS = "forum_checkvoting_success";

export const FORUM_GET_STATEMENTS = 'forum_get_statements';
export const FORUM_GET_STATEMENTS_SUCCESS = 'forum_get_statements_success';
export const FORUM_GET_STATEMENTS_ERROR = 'forum_get_statements_error';

export const FORUM_POST_STATEMENT = 'forum_post_statement';
export const FORUM_POST_STATEMENT_SUCCESS = 'forum_post_statement_success';
export const FORUM_POST_STATEMENT_ERROR = 'forum_post_statement_error';

export const FORUM_GET_COMMENTS = 'forum_get_comments';
export const FORUM_GET_COMMENTS_SUCCESS = 'forum_get_comments_success';
export const FORUM_GET_COMMENTS_ERROR = 'forum_get_comments_error';

export const USER_SET_USERFRAGEN = 'user_set_userfragen';
export const USER_SET_USERFRAGEN_SUCCESS = 'user_set_userfragen_success';
export const USER_SET_USERFRAGEN_ERROR = 'user_set_userfragen_error';

export const FORUM_POST_COMMENT = 'forum_post_comment';
export const FORUM_POST_COMMENT_SUCCESS = 'forum_post_comment_success';
export const FORUM_POST_COMMENT_ERROR = 'forum_post_comment_error';

export const TOPIC_GET_RESULTS = 'topic_get_results';
export const TOPIC_GET_RESULTS_ERROR = 'topic_get_results_error';
export const TOPIC_GET_RESULTS_SUCCESS = 'topic_get_results_success';

export const FORUM_INPUT_CHANGE = 'forum_input_change';

export const VOTE = 'vote';
export const VOTE_SUCCESS = 'vote_success';
export const VOTE_ERROR = 'vote_error';

export const SELECT_WINNERSTATEMENT = 'select_winnerstatement';
export const SELECT_WINNERSTATEMENT_SUCCESS = 'select_winnerstatement_success';
export const SELECT_WINNERSTATEMENT_ERROR = 'select_winnerstatement_error';

export const LIKE_POST = 'form_like_post';
export const LIKE_POST_SUCCESS = 'form_like_success';
export const LIKE_POST_ERROR = 'form_like_error';

export const DISLIKE_POST = 'form_dislike_post';
export const DISLIKE_POST_SUCCESS = 'form_dislike_success';
export const DISLIKE_POST_ERROR = 'form_dislike_error';

export const USER_SHOW_POINTS = 'user_show_points';
export const USER_HIDE_POINTS = 'user_hide_points';

export const AUTH_SIGNIN = 'auth_signIn';
export const AUTH_SIGNIN_SUCCESS = 'auth_signIn_success';
export const AUTH_SIGNIN_ERROR = 'auth_signIn_error';
export const AUTH_AUTOSIGNIN_ERROR = 'auth_autoSignIn_error';

export const AUTH_CHECKVOTING = "auth_checkvoting";
export const AUTH_CHECKVOTING_ERROR = "auth_checkvoting_error";
export const AUTH_CHECKVOTING_SUCCESS = "auth_checkvoting_success";

export const AUTH_SIGNOUT = 'auth_signOut';