import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router'; // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router';
import { history } from './configureStore'

import './AppRouterConnected.css';

import StatementDetail from './components/forum/StatementDetail';
import StatementList from './components/forum/StatementList';
import Home from './components/home/Home';
import Login from './components/login/Login';
import Saeulenhalle from './components/sauellenhalle/Saeulenhalle';
import AppPointsOverlay from './components/ui/AppPointsOverlay';
import AppOverlayLoading from './components/ui/AppOverlayLoading';
import Regeln from './components/home/Regeln';
import Voting from './components/voting/Voting';
import VotingResult from './components/voting/VotingResult';
import VotingFrage from './components/voting/VotingFrage';
import TopicResults from './components/sauellenhalle/TopicResults';
import Fragen from './components/forum/Fragen';
import Datenschutz from './components/home/Datenschutz';





class AppRouterConnected extends Component {

    constructor(props) {
        super();
        this.hideUserScoreTimeout = 0;
    }

    componentDidMount() {

        //ganz ganz böder hack!!!!!
        const pathname = window.location.pathname;
        if (pathname!=='/') {
            window.location.href='/';
        }
     
    }


    render() {


        console.log('CHANGE RERENDER------------------------------------')

        const { isSignedIn } = this.props.auth;
       

        

       //const isSignedIn = true;

        return(
            <ConnectedRouter history={history}> 

            <AppPointsOverlay/>
           <AppOverlayLoading/>
                <div className="App">
                
                    {isSignedIn && (
                        <Switch>
                        <Route exact path="/voting">
                            <Voting />
                        </Route>
                        <Route exact path="/voting/select">
                            <VotingFrage />
                        </Route>
                        <Route exact path="/voting/result">
                            <VotingResult />
                        </Route>
                        <Route exact path="/saeulenhalle/results/:tid">
                            <TopicResults />
                        </Route>
                        <Route exact path="/saeulenhalle/:tid/:id">
                            <StatementDetail />
                        </Route>
                        <Route exact path="/saeulenhalle/:tid">
                            <StatementList />
                        </Route>
                     
                        <Route exact path="/saeulenhalle">
                            <Saeulenhalle />
                        </Route>
                        <Route exact path="/fragen">
                            <Fragen />
                        </Route>
                        <Route exact path="/regeln">
                            <Regeln />
                        </Route>
                        <Route exact path="/datenschutz">
                            <Datenschutz />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                        </Switch>
                    )}
                    {!isSignedIn && (
                    <Switch>
                          <Route exact path="/datenschutz">
                            <Datenschutz />
                        </Route>
                        <Route>
                            <Login />
                        </Route>
                       
                        </Switch>
                    )}
                </div>
            </ConnectedRouter>
        )
    }

}

const mapStateToProps = (state) => {


    return {
      auth: state.auth,
    };
  };

export default connect(mapStateToProps,{})(AppRouterConnected);