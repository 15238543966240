import React from 'react';
import './AppLetterCounter.css';

export default function AppLetterCounter ({letters, maxLetters}) {

    return(
        <div className="appLetterCounter">{letters}/{maxLetters} Zeichen</div>
    )

};
