import React from 'react';
import AppButton, {AppButtonColor} from './AppButton';
import './AppOverlay.css';

export default function AppOverlay ({title, msg, isVisible, onClose}) {
    console.log(msg);
    return(
        <div style={isVisible ? {display: 'block'} : {display: 'none'}} onClick={onClose} className="appOverlayContainer">
        <div className="appOverlayContent">
            <div className="appOverlayTitle">{title}</div>
            <div className="appOverlayMessage">{msg}</div>
            <AppButton onPress={onClose} title="ok" color={AppButtonColor.PRIMARY}></AppButton>
        </div>
    </div>
    )
};
