import { push } from 'connected-react-router'
import { API_URL } from './../globals/globals'
import { objectToQueryString } from '../globals/helpers';

import {
  AUTH_SIGNIN,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNIN_ERROR,
  AUTH_AUTOSIGNIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_SHOW_POINTS,
  FORUM_SETUP,
} from './types';


const GROUP_ID = 'testat';

export const autoSignIn = ({accesstoken}) => {

  console.log("AUTOSIGN: "+accesstoken)

  return (dispatch) => {
    dispatch({type: AUTH_SIGNIN, payload: {}});
    const postData = {
      accesstoken: accesstoken,
    } 

    const queryString = objectToQueryString(postData)
    console.log(queryString);

      fetch(API_URL+'?action=autoLogin',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: queryString,
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
              return(response.json())


          } else {
              dispatch({type: AUTH_AUTOSIGNIN_ERROR, payload: 'Fehler'});
              return []
          }
        })
        .then((responseJson) => {
        
          if (responseJson !== []) {
            console.log(responseJson);
            if (responseJson.response.result==="success") {
              console.log('YES');
              console.log(responseJson);
              onSignInSuccess({dispatch, responseJson});
            } else {
              console.log('ERROR: ' + responseJson.response.msg);
                dispatch({type: AUTH_AUTOSIGNIN_ERROR, payload: responseJson.response.msg});
            }
          } else {
            dispatch({type: AUTH_AUTOSIGNIN_ERROR, payload: 'Fehler'});
          }
          
        })
        .catch((error) => {
          console.log(error);
          dispatch({type: AUTH_SIGNIN_ERROR, payload: 'Verbindungsfehler'});
        
        });

    }
};


export const signIn = ({username, code, gid}) => {


  return (dispatch) => {
    dispatch({type: AUTH_SIGNIN, payload: {}});

    
    const postData = {
      username: username,
      code: code,
      gid: gid,
  }

  const queryString = objectToQueryString(postData)
  console.log(queryString);

    fetch(API_URL+'?action=login',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())


        } else {
            dispatch({type: AUTH_SIGNIN_ERROR, payload: 'Fehler'});
            return []
        }
      })
      .then((responseJson) => {
       
        if (responseJson !== []) {
          console.log(responseJson);
          if (responseJson.response.result==="success") {

            onSignInSuccess({dispatch, responseJson});
            
          } else {
            console.log('ERROR: ' + responseJson.response.msg);
              dispatch({type: AUTH_SIGNIN_ERROR, payload: responseJson.response.msg});
          }
        } else {
          dispatch({type: AUTH_SIGNIN_ERROR, payload: 'Fehler'});
        }
        
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: AUTH_SIGNIN_ERROR, payload: 'Verbindungsfehler'});
       
      });

  }
};

const onSignInSuccess = ({dispatch, responseJson}) => {


      console.log(responseJson);
            //setze userdaten
            dispatch({type: FORUM_SETUP, payload: {topics: responseJson.data.topics, fragen: responseJson.data.fragen}});
        
              
              console.log('USER');
              //setze userdaten
              dispatch({type: USER_LOGIN_SUCCESS, payload: {deadline: responseJson.data.deadline, user: responseJson.data.user, topics: responseJson.data.topics, groupname: responseJson.data.groupname}});
              
              console.log('SCORE');
              //zeige user score
              const score = responseJson.data.score;
              dispatch({type: USER_SHOW_POINTS, payload: score.delta});

              console.log('WECHSELN');
                //erfolgreich
                dispatch({type: AUTH_SIGNIN_SUCCESS, payload: {}});

              //ab ins intro
              //dispatch(push('/'))
               //ab ins intro
              //dispatch(push('/saeulenhalle'))
              dispatch(push('/'))
}
