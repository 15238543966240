import React from 'react';

import './App.css';
import {Provider} from 'react-redux';
import configureStore from './configureStore'
import AppRouterConnected from './AppRouterConnected';

function App() {

  const store = configureStore();

  console.log(store);

  return(
    <Provider store={store}>
      <AppRouterConnected></AppRouterConnected>
    </Provider>
  )

}

export default App;
