import React from 'react';
import './AppButtonIcon.css';

export const AppButtonIconType = {
    CLOSE: 'close',
    BACK: 'back',
    LOGOUT: 'logout',
}

export default function AppButtonIcon ({onPress, icon, isDisabled}) {
    return(
     
        <div onClick={onPress} className={`appButtonIcon ${icon}`}></div>
   
     
    )
};
