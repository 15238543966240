import {
    FORUM_SETUP,
    FORUM_GET_STATEMENTS_SUCCESS,
    FORUM_GET_STATEMENTS_ERROR,
    FORUM_GET_STATEMENTS,
    FORUM_POST_STATEMENT,
    FORUM_POST_STATEMENT_SUCCESS,
    FORUM_POST_STATEMENT_ERROR,
    FORUM_INPUT_CHANGE,
    FORUM_GET_COMMENTS,
    FORUM_GET_COMMENTS_SUCCESS,
    FORUM_GET_COMMENTS_ERROR,
    FORUM_POST_COMMENT,
    FORUM_POST_COMMENT_SUCCESS,
    FORUM_POST_COMMENT_ERROR,
    LIKE_POST_ERROR,
    LIKE_POST_SUCCESS,
    LIKE_POST,
    DISLIKE_POST,
    DISLIKE_POST_SUCCESS,
    DISLIKE_POST_ERROR,
    FORUM_CHECKVOTING,
    FORUM_CHECKVOTING_ERROR,
    FORUM_CHECKVOTING_SUCCESS,
    VOTE_ERROR,
    VOTE,
    VOTE_SUCCESS,
    SELECT_WINNERSTATEMENT,
    SELECT_WINNERSTATEMENT_ERROR,
    SELECT_WINNERSTATEMENT_SUCCESS,
    TOPIC_GET_RESULTS_SUCCESS,
    TOPIC_GET_RESULTS,
    TOPIC_GET_RESULTS_ERROR,
    FORUM_GET_TOPICS,
    FORUM_GET_TOPICS_ERROR,
    FORUM_GET_TOPICS_SUCCESS,
} from './../actions/types';

const INITIAL_STATE = {
 isLoading: false,
 errorMessage: '',
 statements: [],
 comments: [],
 statement: null,
 comment: null,
 topics:[],
 impulses: [
   'Wovor haben wir in unserer Welt Angst?',
   'Wodurch fühlen wir uns in unserer Welt sicher?',
   'Auf welches Gesetz sind wir in unserer Welt besonders stolz?',
   'Warum sind wir die glücklichsten Menschen?',
   'Es gibt keine sozialen Unruhen in unserer Welt, warum?',
   'Wir haben das beste Bildungssystem, wodurch?',
 ],
 deadline: new Date('2020-10-05T12:00:00'),
 isVoting: false,
 votingWinner: null,
 votingStatements: [],
 votingTopic: null,
 votingUsers: [],
 topicResults: null,
 fragen: [],
};

//hack

export const ForumReducer = (state = INITIAL_STATE, action) => {


  switch (action.type) {
    case FORUM_SETUP:
      return {...state, topics: action.payload.topics, fragen: action.payload.fragen}
    case FORUM_INPUT_CHANGE:
      return {...state, errorMessage: '', [action.payload.name]: action.payload.value}
    case FORUM_GET_STATEMENTS:
      return {
        ...state,
        statements: [],
        errorMessage: '',
        isLoading: true,
      };
    case FORUM_GET_STATEMENTS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        statements: action.payload,
        isLoading: false,
      };
    case FORUM_GET_STATEMENTS_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };
    case FORUM_GET_COMMENTS:
        return {
          ...state,
          comments: [],
          errorMessage: '',
          isLoading: true,
        };
    case FORUM_GET_COMMENTS_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          statement: action.payload.statement,
          comments: action.payload.comments,
          isLoading: false,
        };
    case FORUM_GET_COMMENTS_ERROR:
        return {
          ...state,
          errorMessage: action.payload,
          isLoading: false,
        };
    case FORUM_POST_STATEMENT:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
      };
    case FORUM_POST_STATEMENT_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        statements: action.payload,
        isLoading: false,
      };
    case FORUM_POST_STATEMENT_ERROR:
        alert('Du hast bereits einen Vorschlag abgegeben');
        return {
            ...state,
            errorMessage: action.payload,
            isLoading: false,
          };
    case FORUM_POST_COMMENT:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
      };
    case FORUM_POST_COMMENT_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        comments: action.payload,
        isLoading: false,
      };
    case FORUM_POST_COMMENT_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };
    case LIKE_POST:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
      };
    case LIKE_POST_SUCCESS:
       //HACK HACK HACK
        const type = action.payload.objectId.split("_")[0];
        if (type === "s") {
            const s = state.statements.find( st => st.sid === action.payload.objectId );
            if (s) {
              s.likes++;
            }
        } else {
          const c = state.comments.find( co => co.cid === action.payload.objectId );
            if (c) {
              c.likes++;
            }
        }

        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        };
    case LIKE_POST_ERROR:
      alert('Du hast bereits gevoted');
        return {
          ...state,
          errorMessage: action.payload,
            isLoading: false,
          };
    case DISLIKE_POST:
            return {
              ...state,
              errorMessage: '',
              isLoading: true,
            };
    case DISLIKE_POST_SUCCESS:
             //HACK HACK HACK
              const typeDislike = action.payload.objectId.split("_")[0];
              if (typeDislike === "s") {
                  const s = state.statements.find( st => st.sid === action.payload.objectId );
                  if (s) {
                    s.dislikes++;
                  }
              } else {
                const c = state.comments.find( co => co.cid === action.payload.objectId );
                  if (c) {
                    c.dislikes++;
                  }
              }
      
              return {
                ...state,
                errorMessage: '',
                isLoading: false,
              };
    case DISLIKE_POST_ERROR:
      alert('Du hast bereits gevoted');
        return {
          ...state,
          errorMessage: action.payload,
          isLoading: false,
      };
    case FORUM_CHECKVOTING:
        return {...state, isLoading: true}; 
    case FORUM_CHECKVOTING_SUCCESS:
        return {...state,
          isLoading: false,
          isVoting: action.payload.isVoting,
          votingWinner: action.payload.winner,
          votingTopic: action.payload.topic,
          votingStatements: action.payload.statements,
          votingUsers: action.payload.users,
        }; 
    case FORUM_CHECKVOTING_ERROR:
        return {...state, isLoading: false, isVoting: false}; 
    case SELECT_WINNERSTATEMENT:
        return {...state, isLoading: true};
    case SELECT_WINNERSTATEMENT_ERROR:
        return {...state, errorMessage: action.payload, isVoting: false};
    case SELECT_WINNERSTATEMENT_SUCCESS:
      return {...state, 
          isLoading: false,
          isVoting: false,
          votingWinner: null,
          votingTopic: null,
          votingStatements: [],
          votingUsers: [],
      }
    case VOTE:
          return {...state, isLoading: true};
    case VOTE_ERROR:
          return {...state, errorMessage: action.payload};
    case VOTE_SUCCESS:
          return {...state, 
            isLoading: false,
            isVoting: false,
            votingWinner: null,
            votingTopic: null,
            votingStatements: [],
            votingUsers: [],
          }
    case TOPIC_GET_RESULTS:
      return {...state, isLoading: true}
    case TOPIC_GET_RESULTS_SUCCESS: 
      return {...state, isLoading: false, topicResults: action.payload}
    case TOPIC_GET_RESULTS_ERROR:
      return {...state, isLoading: false, errorMessage: action.payload}
    case FORUM_GET_TOPICS:
      return {...state, isLoading: true}
    case FORUM_GET_TOPICS_ERROR:
      return {...state, isLoading: false}
    case FORUM_GET_TOPICS_SUCCESS:
      return {...state, isLoading: false, topics: action.payload.topics}


    default:
      return state;
  }
};
