import React, { Component } from 'react';
import { withRouter } from "react-router";
import { push } from 'connected-react-router'
import AppButton, { AppButtonColor } from '../ui/AppButton';
import AppNavigationHeader from '../ui/AppNavigationHeader';
import './Regeln.css';
import './../../globals/styles.css';
import { connect } from 'react-redux';


class Regeln extends Component {


    constructor(props) {
        super();
    }

    gotoForum = () => {
       this.props.push('/saeulenhalle');
    }

    componentDidMount() {
      window.scrollTo(0, 0);
    }

    render () {

     
      

        return(
         <div className="regelnPage">
              <AppNavigationHeader goHome history={this.props.history} title="Regeln"></AppNavigationHeader>
         
            <div className="homeHeadline">Regeln</div>
            <div className="homeCopy">Du kannst in den einzelnen Kategorien je einen Vorschlag und Kommentare posten.<br/><br/>Nach Abschluss einer Kategorie wählt das System willkürlich eine Spieler*in aus, die eine Entscheidung treffen darf. Es wird in 6 Runden gespielt. <br/><br/>Solltest Du Fragen haben, klicke auf den Hilfe-Button und die Spielmacher*innen sind für Dich da.</div>
            <AppButton onPress={this.gotoForum} title="zum Spiel>" color={AppButtonColor.PRIMARY}></AppButton><br/><br/>
           
           

         </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      user: state.user
    };
  };

  export default withRouter(connect(mapStateToProps,{push})(Regeln));