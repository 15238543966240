import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Statement from './Statement';
import './../../globals/styles.css'
import './StatementList.css'
import TopicTimer from '../sauellenhalle/TopicTimer';
import AppButton, { AppButtonColor } from '../ui/AppButton';
import AppOverlayPost, { AppOverlayPostType } from '../ui/AppOverlayPost';
//import { sortByLikes } from '../../globals/helpers';
import { getStatements, onLike, onDislike, postStatement } from '../../actions/ForumActions';
import AppNavigationHeader from '../ui/AppNavigationHeader';
import AppScore from '../ui/AppScore';
import { topicState } from '../sauellenhalle/Topic';



 class StatementList extends Component {

  constructor(props) {
    super();
    this.state = {
      overlayIsVisible: false,
    }
  }

  componentDidMount() {
    this.props.getStatements({
      accesstoken: this.props.user.accesstoken,
      topicId: this.props.match.params.tid});
  }

  onCloseOverlay = () => {
    this.setState({
      overlayIsVisible: false,
    })
  }

  onClickFragen = () => {
    console.log('CLOSE VISIBLE');
    this.setState({
      overlayIsVisible: true,
    })
  }  

  onSelectStatement = (statement) => {
    
    console.log('SELECTED STATEMENT: ' + statement.sid);
    console.log(this.props.history.push('/saeulenhalle/'+this.props.match.params.tid+'/'+statement.sid));

  }

  goHome = () => {
    this.props.history.push('/');
  }
  
  onSubmitNewPost = ({value}) => {
    this.props.postStatement({
      accesstoken: this.props.user.accesstoken,
      topicId: this.props.match.params.tid, 
      post:value,
    })
    this.setState({
      overlayIsVisible: false,
    })
  }

  onLike = (objectId) => {
   this.props.onLike({
      accesstoken: this.props.user.accesstoken,
      objectId: objectId,
    })
  }
  onDislike = (objectId) => {
    this.props.onDislike({
      accesstoken: this.props.user.accesstoken,
      objectId: objectId,
    })
  }

  findTopic(id) {
    for (let i=0;i<this.props.user.topics.length; i++) {
      const topic = this.props.user.topics[i];
      if (topic.id === id) {
        return topic
      }
    }
    return null;
  }

  render() {

        const { overlayIsVisible } = this.state;
        const { groupname, deadline } = this.props.user;
        const { statements, topics } = this.props.forum;
        const { tid } = this.props.match.params;
  

        const isTopicClosed = topics[tid-1].state !== topicState.OPEN;
       
        const deltaTime = deadline - new Date();
        const isTimeout = deltaTime <=0 ? true : false;

       
      console.log("BIST DU ZU: " + isTopicClosed)
       
        //statements.sort( sortByLikes );

        const topic = topics.find( item => item.id === parseInt(this.props.match.params.tid));
       

        return(
            <div className="page">
              <AppNavigationHeader history={this.props.history} title={topic.title}></AppNavigationHeader>
              <AppOverlayPost type={AppOverlayPostType.STATEMENT} onSubmit={this.onSubmitNewPost} isVisible={overlayIsVisible} onClose={this.onCloseOverlay} title="Dein Vorschlag:"></AppOverlayPost>
              <div className="impulseContainer">
                <div className="impulseContent">{topics[tid-1].impulse}</div>
              </div>
              <div className="statementList">
              {statements.map((statement, index) => {

                  let showBackground;
                  index%2 === 0 ? showBackground = true : showBackground = false;
                  return (
                      <Statement key={index} disableLike={isTopicClosed} showComments showLikes onLike={this.onLike} onDislike={this.onDislike} onPress={this.onSelectStatement} data={statement} showBackground={showBackground}></Statement>
                  );
                })}
                </div> 
               
                  {!isTimeout && (
                    <div className="toolbar">
                      <div className="toolbarCol toolbarCol1">{groupname}</div>
                   
                      <div className="toolbarCol toolbarCol2">
                        {!isTopicClosed && (
                         <AppButton onPress={this.onClickFragen} title="+ Vorschlag" color={AppButtonColor.PRIMARY}></AppButton>
                         )}
                      </div>
                      <div className="toolbarCol toolbarCol3">
                          <AppScore/>
                      </div>
                    </div>
                  )}
                  {isTimeout && (
                    <div className="toolbar">
                    <div className="toolbarCol toolbarCol1">
                     {groupname}
                    </div>
                    <div className="toolbarCol toolbarCol2">
                      <TopicTimer showDetails deadline={deadline}></TopicTimer>
                      </div>
                      <div className="toolbarCol toolbarCol3">
                        <AppScore/>
                      </div>
                    </div>
                  )}
              
            
            </div>
        )
    }

};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    forum: state.forum
  };
};

export default withRouter(connect(mapStateToProps, {
  onLike,
  getStatements,
  postStatement,
  onDislike,
})(StatementList));
