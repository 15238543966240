import React, { Component } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import './../../globals/styles.css';
import './TopicResults.css';
import tmpData from './dummydata/topics.json'; 
import TopicResult from './TopicResult';
import AppButton, { AppButtonColor } from '../ui/AppButton';
import AppNavigationHeader from '../ui/AppNavigationHeader';


class  TopicResults extends Component {


    constructor(props) {
        super();


        const resultsLarge = [];
        const resultsMedium = [];

        for (let i=0; i<tmpData.topics[3].results.length; i++) {
            const el = tmpData.topics[3].results[i];
            if (el.size === "large") {
                resultsLarge.push(el);
            } else {
                resultsMedium.push(el);
            }
        }

        this.state = {
            overlayIsVisible: false,
            overlayMessage: '',
            overlayTitle: '',
            topic: tmpData.topics[3], 
            resultsLarge: resultsLarge,
            resultsMedium: resultsMedium,
            gid: tmpData.gid, 
        }
    }

    gotoDiskussion = () => {
        console.log(this.props.match.params.tid);
        this.props.history.push('/saeulenhalle/' + this.props.match.params.tid);
    }
    gotoForum = () => {
        this.props.history.push('/saeulenhalle');
    }

   
    render() {

        const { topicResults } = this.props.forum;


       

        // <div className="topicResultsDecider">{topicResults.winner.username} ({topicResults.winner.userscore})</div>
       // <div className="topicResultsStatementUsername">{topicResults.winner.statementusername} ({topicResults.winner.statementuserscore})</div>

        return (

            <div className="page">
                 <AppNavigationHeader history={this.props.history} title={topicResults.topic.title}></AppNavigationHeader>
         
            {topicResults.isDecided && (
                <div>
                    <div className="topicResultsDeciderContainer">
                        {topicResults.topic.title} entschieden von
                        {topicResults.winner.userscore > 0 &&(
                        <div className="topicResultsDecider">{topicResults.winner.username}</div>
                        )}
                        {topicResults.winner.userscore <= 0 && (
                        <div className="topicResultsDecider">{topicResults.winner.username}</div>
                        )}
                    </div>
                    <div className="impulseContainer">
                        <div className="impulseContent">{topicResults.topic.impulse}</div>
                    </div>
                       
                        <div className="topicResultsStatement">{topicResults.winner.statement}</div>
                        <div className="topicResultsStatementUsername">{topicResults.winner.statementusername}</div>
                     
                   
            
                    <div className="topicResultsContainer">
                        <div className="topicResultsCategory">
                            <TopicResult title="Einverstanden" amount={topicResults.okCount} size="large"></TopicResult>
                            <TopicResult title="Abgelehnt" amount={topicResults.vetoCount} size="large"></TopicResult>
                            <TopicResult title="Enthalten" amount={topicResults.userCount-topicResults.okCount-topicResults.vetoCount-1} size="large"></TopicResult>
                        </div>
                        <div className="topicResultsCategory">
                            <TopicResult title="Vorschläge" amount={topicResults.statementCount} size="medium"></TopicResult>
                            <TopicResult title="Kommentare" amount={topicResults.commentCount} size="medium"></TopicResult>
                            <TopicResult title="Likes" amount={topicResults.likeCount} size="medium"></TopicResult>
                            <TopicResult title="Dislikes" amount={topicResults.dislikeCount} size="medium"></TopicResult>
                    </div>
                    </div>
                </div>
                )} 
                {!topicResults.isDecided && (
                    <div className="topicResultsDeciderContainer">
                       Das Thema {topicResults.topic.title} wurde noch nicht entschieden. Komme in einer Stunde wieder, um über das Ergbnis abzustimmen.
                    </div>
                )}
              
                <AppButton onPress={this.gotoDiskussion} title="nachlesen >" color={AppButtonColor.SECONDARY}></AppButton><br/><br/>
           
            </div>

        )
    }
};

const mapStateToProps = (state) => {
    return {
      user: state.user,
      forum: state.forum,
    };
  };

export default withRouter(connect(mapStateToProps,{})(TopicResults));