import React from 'react';
import './AppCheckbox.css';





export default function Appcheckbox ({onPress, checked, copy, name}) {
    return(
        <div onClick={() => onPress(name, checked ? true : false)} className="appCheckboxContainer">
            <div className="appCheckboxBox">
                {checked && (
                    <div className="appCheckboxSelected"></div>
                )}
            </div>
            <div className="appCheckboxCopy">{copy}</div>
        </div>
    )
};
