import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Statement from './Statement';

import tmpData from './dummydata/statements.json'; 
import tmpDataComments from './dummydata/comments.json'; 
import './../../globals/styles.css'
import './StatementDetail.css'
import TopicTimer from '../sauellenhalle/TopicTimer';
import AppButton, { AppButtonColor } from '../ui/AppButton';
import AppOverlayPost, { AppOverlayPostType } from '../ui/AppOverlayPost';
import Comment from './Comment';
//import { sortByLikes } from '../../globals/helpers';
import { getComments, postComment, onLike, onDislike } from '../../actions/ForumActions';
import AppNavigationHeader from '../ui/AppNavigationHeader';
import AppScore from '../ui/AppScore';
import { topicState } from '../sauellenhalle/Topic';

 class StatementDetail extends Component {

  constructor(props) {
    super();
    this.state = {
      statement: tmpData.statements[0],
      comments: tmpDataComments.comments,
    }
  }

  componentDidMount() {
    this.props.getComments({
      statementId: this.props.match.params.id,
      accesstoken: this.props.user.accesstoken,
    });
    window.scrollTo(0, 0);
   
  }

  onCloseOverlay = () => {
    this.setState({
      overlayIsVisible: false,
    })
  }

  onClickKommentieren = () => {
    this.setState({
      overlayIsVisible: true,
    })
  }  

  onSubmitNewPost = ({value}) => {
    this.props.postComment({
      accesstoken: this.props.user.accesstoken,
      statementId: this.props.match.params.id,
      post:value,
    })
    this.setState({
      overlayIsVisible: false,
    })
  }

  onPressStatement = (statement) => {

  }

  onPressComment = (comment) => {

  }

  goHome = () => {
    this.props.history.push('/');
  }

  onLike = (objectId) => {
    const  userId = this.props.user.userId;
    console.log(userId + " LIKES " + objectId);
    this.props.onLike({
      accesstoken: this.props.user.accesstoken,
      objectId: objectId, 
    })
  }
  onDislike = (objectId) => {
    const userId = this.props.user.userId;
    console.log(userId + " DISLIKES " + objectId);
    this.props.onDislike({
      accesstoken: this.props.user.accesstoken,
       objectId: objectId,
    })
  }

  render() {

        const { overlayIsVisible,  } = this.state;
        const { groupname, deadline } = this.props.user;
      
        const {comments, statement, topics } = this.props.forum;
      
      
       const {tid} = this.props.match.params;
       const isTopicClosed = topics[tid-1].state !== topicState.OPEN;

      // console.log('TOPIC ZU: ' + isTopicClosed);
        //const { comments } = this.props.forum.comments;

      
        const deltaTime = deadline - new Date();
        const isTimeout = deltaTime <=0 ? true : false;

      const topic = topics.find( item => item.id === parseInt(this.props.match.params.tid));
        
   

        if (statement) statement.comments = comments.length;

        //comments.sort(sortByLikes);

        return(
            <div className="page">
              <AppNavigationHeader history={this.props.history} title={topic.title}></AppNavigationHeader>
              <AppOverlayPost type={AppOverlayPostType.COMMENT} onSubmit={this.onSubmitNewPost} isVisible={overlayIsVisible} onClose={this.onCloseOverlay} title="Dein Kommentar:"></AppOverlayPost>
              {statement && (
                 <Statement showLikes disableLike={isTopicClosed}  onLike={this.onLike} onDislike={this.onDislike} data={statement} onPress={this.onPressStatement} showBackground={true}></Statement>
              )}
              <div className="commentList">
              {comments.map((comment, index) => {

                  //let showBackground;
                  //index%2 === 0 ? showBackground = false : showBackground = true;
                  return (
                      <Comment disableLike={isTopicClosed} onPress={this.onPressComment} onLike={this.onLike} onDislike={this.onDislike} key={index} data={comment} showBackground={false}></Comment>
                  );
                })}
                </div> 

                {!isTimeout && (
                    <div className="toolbar">
                      <div className="toolbarCol toolbarCol1">{groupname}
                      </div>
                      <div className="toolbarCol toolbarCol2">
                        {!isTopicClosed && (
                          <AppButton onPress={this.onClickKommentieren} title="+ Kommentar" color={AppButtonColor.SECONDARY}></AppButton>
                        )}
                      </div>
                      <div className="toolbarCol toolbarCol3">
                          <AppScore/>
                      </div>
                    </div>
                  )}
                  {isTimeout && (
                    <div className="toolbar">
                    <div className="toolbarCol toolbarCol1">{groupname}</div>

                    <div className="toolbarCol toolbarCol2">
                      <TopicTimer showDetails deadline={deadline}></TopicTimer>
                      </div>
                      <div className="toolbarCol toolbarCol3">
                        <AppScore/>
                      </div>
                    </div>
                  )}
               
            
            </div>
        )
    }

};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    forum: state.forum
  };
};


export default withRouter(connect(mapStateToProps,{
  getComments,
  postComment,
  onLike,
  onDislike
})(StatementDetail));
