import React from 'react';
import './TopicCounter.css';


export default function TopicCounter ({statementsCount, commentsCount}) {

    return (
            <div className="topicCounterContainer">
                <div className="topicCounterContent"><span className="topicCounterNumber">{statementsCount}x</span></div>
                <div className="topicCounterContentComment"><span className="topicCounterNumber">+{commentsCount}</span></div>
            </div>
    )

};
