import React, { Component } from 'react';
import { connect } from 'react-redux';
import './AppScore.css';

class AppScore extends Component {

    render() {
       
        // <div className="appScorePoints">{userscore}</div>
        const {userscore, username = "Hallo"} = this.props.user;
        return(
            <div className="appScoreContainer">
                <div className="appScoreTitle">{username}</div>
               
              
            </div>
        )
    }
};


const mapStateToProps = (state) => {
    return {
      user: state.user,
    };
  };
  
  
  export default connect(mapStateToProps,{})(AppScore);
