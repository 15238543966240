import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import AppButton, { AppButtonColor } from './../ui/AppButton';
import {vote} from './../../actions/ForumActions';
import './../../globals/styles.css';
import './Voting.css';

class VotingResult extends Component {

    vote = ({vote}) => {
        console.log('VOTE: ' + vote);
        this.props.vote({
            accesstoken: this.props.user.accesstoken,
            topicId: this.props.forum.votingTopic.id,
           vote: vote,
        })
    }

    renderResult() {

        const {userId} = this.props.user;
        const {votingWinner} = this.props.forum

      

        return (
            
            <div className="votingStepContainer">
               <div className="votingCopy">{votingWinner.username} hat folgenden Vorschlag gewählt:</div>
                <div className="votingCommentContainer">
                    <div className="votingResultText">{votingWinner.statement}</div>
                   
                </div>
                {userId === votingWinner.uid && (
                    <div className="votingButtonContainer">
                        <AppButton title="andere" color={AppButtonColor.TRANSPARENT}></AppButton>
                        <AppButton title="ok" color={AppButtonColor.PRIMARY}></AppButton>
                    </div>
                )}
                {userId !== votingWinner.uid && (
                    <div className="votingButtonContainer">
                        <AppButton title="veto" onPress={()=>this.vote({vote: 0})} color={AppButtonColor.ALERT}></AppButton>
                        <div className="votingSeperator"></div>
                        <AppButton title="ok" onPress={()=>this.vote({vote: 1})} color={AppButtonColor.PRIMARY}></AppButton>
                    </div>
                )}
               
            </div>
        )
    }

    
    render() {

        const { votingTopic } = this.props.forum;


        return (
            <div className="page voting">
              {votingTopic && (
               <div>
                <div className="votingHeader">
                    <div className="votingHeadline">Losentscheid</div>
                    <div className="votingTopic">{votingTopic.title}</div>
                </div>
                <div className="votingContainer">
                    <div className="votingImpulseContainer">
                    <div className="votingImpulseContent">{votingTopic.impulse}</div>
                </div>
                    {this.renderResult()}
                </div>
                </div>
                )}
            </div>
        )
    }

}

const mapStateToProps = (state) => {

    return {
      forum: state.forum,
      user: state.user,
    };
  };

export default withRouter(connect(mapStateToProps,{
    vote,
})(VotingResult));