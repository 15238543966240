import React, { Component } from 'react';
import AppButton, {AppButtonColor} from './AppButton';
import AppButtonIcon, { AppButtonIconType } from './AppButtonIcon';
import AppLetterCounter from './AppLetterCounter';
import './AppOverlayPost.css';





export const AppOverlayPostType = {
    STATEMENT: 'statement',
    COMMENT: 'comment',
}

class AppOverlayPost extends Component {

    constructor(props) {
       
        super();
        this.state = {
            post: '',
            maxLetters: 120,
        }
    }

    onClose = () => {
        this.props.onClose();
    }

    onChangeStatement = (evt) => {
      
        if (evt.target.value.length>this.state.maxLetters) return;
        this.setState({
            post: evt.target.value,
        })
    }
    
    submitPost = (evt) => {
        this.props.onSubmit({value: this.state.post});
        this.setState({
            post: '',
        })
    }
    render() {

        const {title, isVisible, type } = this.props;
        const {post, maxLetters } = this.state;

        return(
        <div style={isVisible ? {display: 'block'} : {display: 'none'}} className="appOverlayPostContainer">
            <div className={`appOverlayPostContent ${type}`}>
                <div style={{textAlign:'right'}}><AppButtonIcon onPress={this.onClose} icon={AppButtonIconType.CLOSE}></AppButtonIcon></div>
                <div className="appOverlayPostTitle">{title}</div>
                <form className="appOverlayPostForm">
                    <textarea onChange={this.onChangeStatement} id="postValue" name="postValue" value={post} placeholder='Hier eingeben' className="appOverlayPostTextarea"></textarea>
                </form>
                <AppLetterCounter letters={post.length} maxLetters={maxLetters}></AppLetterCounter>
          
                    <AppButton onPress={this.submitPost} title="abschicken" color={AppButtonColor.WHITE}></AppButton>
             
            </div>
        </div>
        )
    }

};

export default AppOverlayPost;

