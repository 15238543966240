import React from 'react';
import './AppTextInput.css';

export default function AppTextInput ({onChange, title, name, required, placeholder, value, type='text'}) {
    return(
        <div className="appTextInputContainer">
            <div className="appTextInputTitle" dangerouslySetInnerHTML={{__html: title}}></div>
             <div className="appTextInputField">
                <input autoComplete="off" type={type} value={value} name={name} id={name} required={required} placehoder={placeholder} onChange={onChange} className="appTextInput"></input>
             </div>
       </div>
    )
};
