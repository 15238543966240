import React, {Component} from 'react';
import './TopicTimer.css';


const UPDATEINTERVALMS = 5000;

export default class TopicTimer extends Component {


    constructor(props) {
      super();
      this.state = {
          timeString: "00:00",
          isTimeout: true,
      }
      this.timer = null;
    }

    componentDidMount() {
        this.updateTimer();
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer()
    }

    updateTimer = () => {
        const now = new Date();
        const { deadline} = this.props;
        const deltaMS = deadline - now;

        console.log("DELTA TIME: " + deadline);
        
        let timeString = "00:00";
        if (deltaMS > 0) { 
            let hours = Math.floor(deltaMS/1000/60/60);
            let minutes = Math.ceil((deltaMS/1000/60)%60);
            //const minutes = "00";

            if (hours<10) hours="0"+hours;
            if (minutes<10) minutes="0"+minutes;
            timeString = hours + ':' + minutes;
            this.setState({
                isTimeout: false,
                timeString: timeString,
            })
        } else {
            this.setState({
                isTimeout: true,
                timeString: 'vorbei',
            })
        }
      
    }

    startTimer = () => {
        this.timer = setInterval(this.updateTimer,UPDATEINTERVALMS);
    }

    stopTimer = () => {
        console.log('Stop Timer');
        clearInterval(this.timer);
    }

    render() {

        const { timeString } = this.state;
        const { showDetails, textColor = '#ffffff' } = this.props;

        return (
            <div className="topicTimerContainer" style={!showDetails ? {backgroundColor:'#000000', padding:'0.3em 0.5em 0.0em 0.5em'} : {}}>
                {showDetails && (<div className="topicTimerTitle">Std:Min</div>)}
                <div className="topicTimer" style={{color: textColor}}>{timeString}</div>
            </div> 
        )
    }
}