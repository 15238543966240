import {
  USER_INPUT_CHANGE,
  USER_SHOW_POINTS, 
  USER_HIDE_POINTS,
  USER_SET_USERFRAGEN,
  USER_SET_USERFRAGEN_ERROR,
  USER_SET_USERFRAGEN_SUCCESS,
  USER_REGISTER,
  USER_REGISTER_ERROR,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_NEXT,
  USER_REGISTER_PREV,
} from './types';
import { push } from 'connected-react-router'
import { objectToQueryString } from '../globals/helpers';
import { API_URL } from './../globals/globals'

export const onChangeUserInput = ({name, value}) => {
  return {type: USER_INPUT_CHANGE, payload: {name: name, value: value}};
}

export const onRegisterNextStep = () => {
  return {type: USER_REGISTER_NEXT};
}

export const onRegisterPrevStep = () => {
  return {type: USER_REGISTER_PREV};
}


export const onRegister = ({vorname, nachname, username, handynummer, groupId, datenschutz, disclaimer}) => {
  return (dispatch) => {

    dispatch({type: USER_REGISTER, payload: {}});

    const postData = {
      vorname, 
      nachname,
      handynummer,
      username,
      gid: groupId,
      datenschutz,
      disclaimer
    }

    const queryString = objectToQueryString(postData);
    console.log(queryString);

    //const cb=Math.round(Math.random()*1000);

    fetch(API_URL+'?action=register',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
      
        if (response.status === 200) {
          console.log(response);
            return(response.json())
        } else {
            dispatch({type: USER_REGISTER_ERROR, payload: 'Fehler'});
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.response.result === "success") {
          dispatch({type: USER_REGISTER_SUCCESS, payload: {uid: responseJson.data.uid }});
        } else {
          dispatch({type: USER_REGISTER_ERROR, payload: responseJson.response.msg});
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: USER_REGISTER_ERROR, payload: 'Verbindungsfehler'});
      });

  }

}

export const onShowPointsOverlay = ({points}) => {
  return {type: USER_SHOW_POINTS, payload: points};
}
export const onHidePointsOverlay = () => {
  return {type: USER_HIDE_POINTS, payload: 0};
}

export const setFragen = ({accesstoken, userId, groupId, fragen}) => {
  return (dispatch) => {

    dispatch({type: USER_SET_USERFRAGEN, payload: {}});

    const postData = {
      //uid: userId,
      fragen: fragen,
      //gid: groupId,
      accesstoken,
    }

    const queryString = objectToQueryString(postData);
    console.log(queryString);

    fetch(API_URL+'?action=setUserFragen',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
            return(response.json())
        } else {
            dispatch({type: USER_SET_USERFRAGEN_ERROR, payload: 'Fehler'});
        }
      })
      .then((responseJson) => {
          dispatch({type: USER_SET_USERFRAGEN_SUCCESS});
          dispatch({type: USER_SHOW_POINTS, payload: responseJson.data.score.delta});
          dispatch(push('/saeulenhalle'))
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: USER_SET_USERFRAGEN_ERROR, payload: 'Verbindungsfehler'});
      });

  }

}
