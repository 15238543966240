import React from 'react';
import './VoteButton.css';
//import iconLike from './../../assets/icons/like.svg';
//import iconDislike from './../../assets/icons/dislike.svg';

export const VoteButtonTypes = {
    LIKE : 'like',
    DISLIKE : 'dislike',
}

export const VoteButtonFor = {
    COMMENT : 'comment',
    STATEMENT : 'statement',
}

export default function VoteButton ({type, voteFor, userId, objectId, count, onVote, isDisabled, isHighlighted, float}) {

    let icon;

    switch (type) {
        case VoteButtonTypes.LIKE:
            switch (voteFor) {
                case VoteButtonFor.COMMENT:
                    icon = "likeComment";
                    break;
                default:
                    icon = "likeStatement";
                    break;
            }
            break;
        case VoteButtonTypes.DISLIKE:
        default:
            switch (voteFor) {
                case VoteButtonFor.COMMENT:
                    icon = "dislikeComment";
                    break;
                default:
                    icon = "dislikeStatement";
                    break;
            }
            break;
    }
  

    return(
        <div onClick = {
            !isDisabled ? () => onVote({
                userId: userId,
                objectId: objectId,
                count: type === VoteButtonTypes.LIKE ? 1 : -1
             }): null
        } style={isDisabled ? {opacity: isHighlighted ? 1 : 0.5, float:float} : {float:float}} className="voteButtonContainer">
            <div className={`voteButtonCount ${icon}`}>{count}</div>
            <div className={`voteButtonIcon ${icon}`} alt={type}></div>
        </div>
    )

};
