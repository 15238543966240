import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import AppButton, { AppButtonColor } from './../ui/AppButton';
import {checkForVoting} from './../../actions/ForumActions';
import './../../globals/styles.css';
import './Voting.css';

class Voting extends Component {

    constructor(props) {
        super();
    }

    componentDidMount() {
        if (!this.props.forum.isVoting) {
            this.props.history.goBack();
        }
    }

    gotoResult = () => {
        this.props.history.push('/voting/result');
    }

    gotoSelect = () => {
        this.props.history.push('/voting/select');
    }

    renderMe() {
        return (
            <div className="votingStepContainer">
                <div className="votingInfoTopContainer">
                    <div className="votingInfoTopContainerCopy">
                        Das Los ist auf<br/>
                        <span className="votingInfoTopContainerUser">dich</span><br/>
                        gefallen
                    </div>
                </div>
                <div className="votingInfoBottomContainer">
                    <div className="votingInfoBottomContainerCopy" >Wähle jetzt einen Vorschlag aus der Diskussion.</div>
                </div>
                <div className="votingButtonContainer">
                    <AppButton title="weiter" onPress={this.gotoSelect} color={AppButtonColor.PRIMARY}></AppButton>
                </div>
            </div>
        )
    }

    renderOther(winner) {
        return (
            <div className="votingStepContainer">
                <div className="votingInfoTopContainer">
                    <div className="votingInfoTopContainerCopy">
                        Das Los ist auf<br/>
                        <span className="votingInfoTopContainerUser">{winner.username}</span><br/>
                        gefallen
                    </div>
                </div>
                <div className="votingInfoBottomContainer">
                    <div className="votingInfoBottomContainerCopy" >Bist du mit der Entscheidung einverstanden?</div>
                </div>
                <div className="votingButtonContainer">
                    <AppButton title="weiter" onPress={this.gotoResult} color={AppButtonColor.PRIMARY}></AppButton>
                </div>
            </div>
        )
    }



    render() {

        const { votingWinner, votingTopic } = this.props.forum;
        const { userId } = this.props.user;

        return (
            <div className="page voting">
                <div className="votingContainer centered">
                    <div className="votingHeadline">Losentscheid</div>
                    <div className="votingTopic">{votingTopic.title}</div>
                    {votingWinner.uid === userId && this.renderMe()}
                    {votingWinner.uid !== userId  && this.renderOther(votingWinner)}
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
      user: state.user,
      forum: state.forum,
    };
  };

export default withRouter(connect(mapStateToProps,{
    checkForVoting,
})(Voting));