import React, { Component } from 'react';
import { connect } from 'react-redux';
import './AppOverlayLoading.css';

class AppOverlayLoading extends Component {

    render() {

        const {isLoading} = this.props.user;

        if (isLoading) {
            return(
                <div className="appOverlayLoading">
                <div className="appOverlayLoadingContent">
                   loading...
                </div>
            </div>
            )
        } else {
            return (
                <div/>
            )
        }

        
     }
};

const mapStateToProps = (state) => {
    return {
      user: state.user,
      forum: state.forum,
    };
};
  
export default connect(mapStateToProps,{})(AppOverlayLoading);