import React, { Component } from 'react';
import './Comment.css';
import './../../globals/styles.css';
import VoteButton, { VoteButtonFor, VoteButtonTypes } from './VoteButton';


export default class Comment extends Component {

    constructor(props) {
        super();
        this.state = {
            loading: false,
        }
    }

    onVote = ({objectId, userId, count}) => {
        console.log(userId + ' VOTES ' + count + ' FOR ' + objectId)
        if (count > 0) {
            this.props.onLike(objectId)
        } else {
            this.props.onDislike(objectId)
        }
    }

    render() {

        const { cid, comment, username, userscore, likes, dislikes, userVote } = this.props.data;
        const {onPress, data, selected, disableLike} = this.props
        const showBackground = this.props.showBackground;
       
        let selectedClassName='';
        if (selected) selectedClassName='selected';

        //  <div className="commentUserscore">{userscore} Punkte</div>

        return(
            <div onClick={()=>onPress(data)} className={`commentContainer ${selectedClassName}`}>
                {showBackground && (<div className="commentBackground"></div>)}
                <div className="commentUsername">{username}</div>
              
                <div className="commentCopy">{comment}</div>
                <div className="commentVotingContainer">
                    <VoteButton float="left" isDisabled={disableLike} isHighlighted={userVote > 0} count={likes} type={VoteButtonTypes.LIKE} voteFor={VoteButtonFor.COMMENT} objectId={cid} userId="me" onVote={this.onVote}></VoteButton>
                    <VoteButton float="right" isDisabled={disableLike} isHighlighted={userVote < 0} count={dislikes} type={VoteButtonTypes.DISLIKE} voteFor={VoteButtonFor.COMMENT} objectId={cid} userId="me" onVote={this.onVote}></VoteButton>
                </div>
                <div className="commentLine"></div>
            </div>
        )
    }

};
