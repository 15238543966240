import React from 'react';


export default function TopicBackground({position, backgroundColor, strokeColor, scale}) {

   

    switch (position) {
        case 1:
        case 5:
        case 2:
            return (
                <svg height={200*scale} width={200*scale}>
                     <polygon points={`${20*scale},${30*scale} ${180*scale},${10*scale} ${170*scale},${180*scale}, ${10*scale},${170*scale}`} style={{fill:backgroundColor, stroke:strokeColor, strokeWidth:10*scale}} />
                </svg>
            );
        default:
            return (
                <svg height={200*scale} width={200*scale}>
                     <polygon points={`${10*scale},${10*scale} ${190*scale},${30*scale} ${180*scale},${170*scale}, ${30*scale},${190*scale}`} style={{fill:backgroundColor, stroke:strokeColor, strokeWidth:10*scale}} />
                </svg>
            );
    }
   
}