import { createBrowserHistory } from 'history'
import {composeWithDevTools} from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux'
import ReduxThunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducer'
export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        ReduxThunk, // for dispatching history actions
        // ... other middlewares ...
      ),
    ),
  )

  return store
}